/**
 * Define namespace
 */
var Prestito = Prestito || {}

/**
 * Prestito core object
 */
Prestito.Application = function() {

  //Local variables
  var application = this;
  
  /**
   * Class attributes.
   *
  */
  this.showConsole = true; // show js logs in console
  this.env = null; // application execution environment
  this.forceShowConsole = false; // force console enabled
  this.appStart = false; // application started
  this.skipLoadFiles = false; // skip load files
  this.ajaxLoaderTarget = null; // force loader ajax target (forced outside the ajax call ex. ajax call in library)
  this.jobsEnded = 0; // number of loading jobs ended

  this.temp_btnmanagerclick = false;
  this.temp_resend = false;
  this.temp_manageotp = false;
  this.redirectLink = "";
  let inviaDettagliCaptcha = false;

  var BASE64_MARKER = ';base64,';
  var zoomPdf = 1.5;
  /**
   * Application Bootstrap
   */
  this.boot = function () {
    // log
    application.console.log("Application Bootstrap");

    window.jobsLoading = {}; // init global load files jobs repository
    application.setupEnvironment(); // setup environment

    // setup logger
    application.setupLogger();

    // log data layer object
    application.logDataObject();

    // init common components
    application.initCommonComponents();

    $(".dropdown").hover(

      function() {
    
        if(application.isDesktopXL() || application.isDesktop()) {
    
          $(".dropdown-toggle", this).addClass("active");
          $(".dropdown-toggle", this).parent().addClass("open");
    
          // if user desktop menu calculate max-height
          if($(this).hasClass('user-menu-trigger')) {
    
            var menuContainer = $(this).find('.dropdown-menu');
            var menuContent = $('.user-scrollable-menu');
    
            var menuHeight = menuContainer.height();
            var windowHeight = document.documentElement.clientHeight;
            var menuTopValue = menuContainer[0].offsetTop;
    
            if((menuHeight + menuTopValue) > windowHeight) {
              menuContent.css({
                maxHeight: (windowHeight - menuTopValue)
              });
              new SimpleBar(menuContent[0]);
            }
    
            // Add click event to remove hover on tablet
            $(".content-wrapper").on('click', function () {
              $(".user-menu-trigger").removeClass('open');
            });
    
          }
    
        }
    
      },
    
      function() {
    
        if( application.isDesktopXL() || application.isDesktop() ) {
    
          $(".dropdown-toggle", this).parent().removeClass("open");
          $(".dropdown-toggle", this).removeClass("active");
    
          // if user desktop menu reset max-height
          if($(this).hasClass('user-menu-trigger')) {
            $('.user-scrollable-menu').css({
              maxHeight: ""
            });
            // remove click event for tablet
            $(".content-wrapper").off("click");
          }
    
        }
    
      }
    
    );

  };



  /* --------------------- INIT ------------------------------------------------------------------------------ */

  /**
   * Init common components
   *
  */
  this.initCommonComponents = function() {
    
    if (application.appStart) return;

    // load styles
    var styles = {
      // libraries
      "alertify/css/alertify": "lib",
    };
    application.loadStyle(styles);

    var scripts = {
      // libraries
      "alertify/js/alertify": "lib",
      "dotdotdot/js/dotdotdot": "lib",
      "jspdf/pdf": "lib",

    };

    application.loadScript(scripts, function() {
      
      // log
      application.console.log("Init commons components");
      
      application.initCookie(); // init cookie
      application.initAjax(); // init ajax calls
      application.initResponsive(); // init check responsive
      application.setupFullWidthComponents(); // setup full width component
      application.setupConfirmMessages(); // setup automatic confirms
      application.setupTooltip(); // setup tooltip
      application.setupAccordion(); // setup accordion
      application.setupSelectForm(); // setup select form
      application.setupCharts();
      application.initComponentsByType();
      application.setupResponsiveBackgroundImage();
      application.setupResponsiveImage();
      application.setupSlider();
      application.setupOtpControl();
      application.setupNotificationMessages();
      application.setupOnScroll();
      application.setupLazyLoad(); // setup lazy load
      application.setupTinySlider(); // setup tiny slider
      if($('[data-component="sliderContentComponent"]').length > 0) {
        application.SliderContent();
      }
      // logica modali promo tolta
/*       if($( '[data-component="modalNuovaLiquidita"]' ).length > 0){
        application.setModalNuovaLiquidita();
      }else{
        application.setModalIncrementalInterest();
      } */

      application.setNuoviPianiModal();
      if($(".aperturaLibretto__steps").length > 0){
        application.aperturaLibretto();
        application.activateCard();
      } 
      if($(".dematLibretto__steps").length > 0){
        application.dematLibretto();
      } 
      if($('[data-component="modaleAbbandonoFlussi"]').length > 0){
        application.modaleAbbandonoFlussi();
      }
      if ($('[data-component="associazione-iban-step-1 importa"], [data-component="associazione-iban-step-2 importa"] ').length > 0) { 
        application.associaIban();
      }
      if ($('[data-component="entryAperturaLibrettoComponent"]').length > 0 && (application.isMobile() || application.isTablet())) {
        application.toggleAccordion();
      }
      //application.setupDot();


      $(document).on('click', '[data-button-mostra-piu]', function() {
        $('[data-container-mostra-vincoli]').removeClass('piani__mostraMeno');
        $(this).addClass('hide');
        $('[data-button-mostra-meno]').removeClass('hide');
      });

      $(document).on('click', '[data-button-mostra-meno]',function() {
        $('[data-container-mostra-vincoli]').addClass('piani__mostraMeno');
        $(this).addClass('hide');
        $('[data-button-mostra-piu]').removeClass('hide');
      });

      if($('[data-component="alertBannerComponent"]').length > 0) {

        if(!sessionStorage.getItem("closeAlertBanner")) {
          $('[data-component="alertBannerComponent"]').removeClass('hide');
        }

        $(document).on('click', '[data-component="alertBannerComponent"] [close__button]', function() {
          sessionStorage.setItem("closeAlertBanner", true);
          $(this).parents('[data-component="alertBannerComponent"]').addClass('hide');
        });
      }

      if($('[data-component="stepsContainerComponent"]').length > 0) {

        $('[data-component="stepsContainerComponent"]').each(function() {
          if($(this).find('div > .container-fluid').length > 1) {
            $(this).find('.last__arrow').removeClass('hide').addClass('visible-xs visible-sm');
          }
        });
      }

      //TODO nel file header.js
      $(document).on('click', '[data-toggle="collapse-side"]', function() {
        if(application.isMobile() || application.isTablet()) {
          if($(this).hasClass('collapsed')) {
            //OPEN left menu
            $(this).removeClass('collapsed');
            $('.nav-menu.side-collapse').removeClass('in');
            $('.nav-menu-mobile').addClass('nav_menu__open');
          } else {
            //CLOSE left menu
            $(this).addClass('collapsed');
            $('.nav-menu.side-collapse').addClass('in');
            $('.nav-menu-mobile').removeClass('nav_menu__open');
          }
        }
      });

      $(document).on('click', '.dropdown__mobile', function(e) {
        if(!application.isMobile() && !application.isTablet() || !$(this).hasClass('whit__child')) {
          if(e.target.classList.contains('title')) {
            window.open($(this).attr('href'), $(this).attr('target')||"_self");
          }
        }
      });

      $(document).on('click', '#gototop', function() {
        $('document').ready(function() {
          $("html, body").animate({ scrollTop: 0 }, 500);
        });
      });


      document.fonts.load('1em "Material Symbols Outlined"').then(function () {
        // remove .loading-icon class from body
        $('body').removeClass('loading-icon');
      });

    });

  }
  
  this.setupOnScroll = function() {
    var getUrlParameter = function getUrlParameter(sParam) {
      var sPageURL = window.location.search.substring(1),
          sURLVariables = sPageURL.split('&'),
          sParameterName,
          i;
  
      for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');
  
          if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
          }
      }
      return false;
    };

    //if is a class
    if(getUrlParameter('scrollTo')) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $(getUrlParameter('scrollTo')).offset().top - $('header').height()
      }, 500);

      //if is a data-component
    } else if(getUrlParameter('scrollToData')) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('[data-component="' + getUrlParameter('scrollToData') + '"]').offset().top - $('header').height()
      }, 500);
    }else if(getUrlParameter('scrollToOffer')) {
      $([document.documentElement, document.body]).animate({
          scrollTop: $('[data-simulator-offer="' + getUrlParameter('scrollToOffer') + '"]').offset().top - $('header').height() -100
      }, 500);

      if( $('[data-simulator-offer="' + getUrlParameter('scrollToOffer') + '"]').length > 0) {
          $('[data-simulator-offer="' + getUrlParameter('scrollToOffer') + '"]').find("[data-simula-interesse]").trigger('click');
      };
    } 
  }

  /**
     * set modal promo nuova liquidità
     *
     */
  this.setModalNuovaLiquidita = function() {
    if($( '[data-component="modalNuovaLiquidita"]' ).length > 0){

      var modalHtml = $( '[data-component="modalNuovaLiquidita"]' ).html();
      var modalTitle = $( '[data-component="modalNuovaLiquidita"]' ).data("title");
      var userId = $( '[data-component="modalNuovaLiquidita"]' ).data("user");

      if( $.cookie("pro_mod_H"+userId) != "true" && $('.alertify-logs .show').length < 1){
          if(sessionStorage.getItem("pro_mod_H"+userId) != "true"){
              application.confirm(
                  modalHtml,
                  "<span class='promo_modal_tag'>NOVITÀ</span>" + modalTitle,
                  function() {},
                  function() {},
                  "",
                  ""
              );

              $('.alertify .dialog').addClass('modalNuovaLiquidita');
          }
      }else{
        application.setModalIncrementalInterest();
      }

      $(".madal_promo_btn .btn").on('click', function(e) { 

          $(".msgModal .icon-close").trigger("click");

          if($(this).data("scroll-to").length > 0){
              var scrollToOffer = $(this).data("scroll-to");
              $([document.documentElement, document.body]).animate({
                  scrollTop: $('[data-simulator-offer="'+scrollToOffer+'"]').offset().top - $('header').height() -100
              }, 500);

              $('[data-simulator-offer="'+scrollToOffer+'"]').first().find("[data-simula-interesse]").trigger('click');
          }
      });

      $(".msgModal .icon-close").on('click', function(e) { 
          if($( '[data-component="modalNuovaLiquidita"]' ).data("is-promo")){
              if ($('[name="checkModalPromo"]').val() == 'true') {
                  document.cookie = "pro_mod_H"+userId+"=true; expires=Mon, 01 May 2023 02:00:00 GMT; path=/";
              }
          }else{
              if ($('[name="checkModalPromo"]').val() == 'true') {
                  document.cookie = "pro_mod_H"+userId+"=true; expires=Thu, 01 Jan 2100 00:00:00 GMT; path=/";
              }
          }
          sessionStorage.setItem("pro_mod_H"+userId, "true")
      });

      $('[name="checkModalPromoHide"]').change(function () {
          if ($('[name="checkModalPromo"]').val() == 'true') {
              $('[name="checkModalPromo"]').val('false');
          } else {
              $('[name="checkModalPromo"]').val('true');
          }
      });

      $('body .dialog nav').remove();
      $('.alertify').addClass('overflow-scroll-modal');
    }

  }

  /**
   * set modal promo interessi incrementali
   *
   */
  this.setModalIncrementalInterest = function() {
    if($( '[data-component="modalIncrementalInterest"]' ).length > 0){

      var modalHtml = $( '[data-component="modalIncrementalInterest"]' ).html();
      var modalTitle = $( '[data-component="modalIncrementalInterest"]' ).data("title");
      var userId = $( '[data-component="modalIncrementalInterest"]' ).data("user");

      if( $.cookie("pro_mod_inc_H"+userId) != "true" && sessionStorage.getItem("pro_mod_inc_H"+userId) != "true" && $('.alertify-logs .show').length < 1){
          application.confirm(
              modalHtml,
              modalTitle,
              function() {},
              function() {},
              "",
              ""
          );

          $('.alertify .dialog').addClass('modalIncrementalInterest');
      }
      

      $(".madal_promo_btn .btn").on('click', function(e) { 

          $(".msgModal .icon-close").trigger("click");

          if($(this).data("scroll-to")){
              var scrollToOffer = $(this).data("scroll-to");
              $([document.documentElement, document.body]).animate({
                  scrollTop: $('[data-simulator-offer="'+scrollToOffer+'"]').offset().top - $('header').height() -100
              }, 500);

              $('[data-simulator-offer="'+scrollToOffer+'"]').first().find("[data-simula-interesse]").trigger('click');
          }else{
              $([document.documentElement, document.body]).animate({
                  scrollTop: $('[data-component="pianiRisparmio__lista"]').offset().top - $('header').height() -100
              }, 500);
              
              $('[data-component="pianiRisparmio__lista"] .filters-tabs-wrapper [data-value="attivo"]').trigger("click");
          }
          
      });

      $(".msgModal .icon-close").on('click', function(e) { 
          if ($('[name="checkModalPromoInc"]').val() == 'true') {
              document.cookie = "pro_mod_inc_H"+userId+"=true; expires=Thu, 01 Jan 2100 00:00:00 GMT; path=/";
          }
          
          sessionStorage.setItem("pro_mod_inc_H"+userId, "true")
      });

      $('[name="checkModalPromoIncHide"]').change(function () {
          if ($('[name="checkModalPromoInc"]').val() == 'true') {
              $('[name="checkModalPromoInc"]').val('false');
          } else {
              $('[name="checkModalPromoInc"]').val('true');
          }
      });

      $('body .dialog nav').remove();
      $('.alertify').addClass('overflow-scroll-modal');

    }

  }

  this.setNuoviPianiModal = function () {
    if ($('[data-component="modalIncrementalInterest"]').length > 0) {

      var modalHtml = $('[data-component="modalIncrementalInterest"]').html();
      var modalTitle = $('[data-component="modalIncrementalInterest"]').data("title");
      var userId = $('[data-component="modalIncrementalInterest"]').data("user");

      console.log($.cookie("pro_mod_nuovi_H" + userId) != "true" && sessionStorage.getItem("pro_mod_nuovi_H" + userId) != "true" && $('.alertify-logs .show').length < 1);

      if ($.cookie("pro_mod_nuovi_H" + userId) != "true" && sessionStorage.getItem("pro_mod_nuovi_H" + userId) != "true" && $('.alertify-logs .show').length < 1) {
        application.confirm(
          modalHtml,
          modalTitle,
          function () { },
          function () { },
          "",
          ""
        );

        $('.alertify .dialog').addClass('modalIncrementalInterest');
      }


      $(".madal_promo_btn .btn").on('click', function (e) {

        $(".msgModal .icon-close").trigger("click");

        // scroll to [data-container-mostra-vincoli]
        $([document.documentElement, document.body]).animate({
          scrollTop: $('[data-container-mostra-vincoli]').offset().top - $('header').height()
        }, 200);

      });

      $(".msgModal .icon-close").on('click', function (e) {

        sessionStorage.setItem("pro_mod_nuovi_H" + userId, "true")
      });

      $(".msgModal .icon-close").on('click', function (e) {
        if ($('.modalIncrementalInterest #hide_promo_modal').is(':checked')) {
          document.cookie = "pro_mod_inc_H" + userId + "=true; expires=Thu, 01 Jan 2100 00:00:00 GMT; path=/";
        }

        sessionStorage.setItem("pro_mod_inc_H" + userId, "true")
      });


      $('body .dialog nav').remove();
      $('.alertify').addClass('overflow-scroll-modal');

    }
  }

  this.prestitoVincolato = function() {

    $(document).on('click', '[data-avvisami-piano-futuro]', function() {


      const servletPromemoriaPianoFuturo = (emailP, _self) => {
        const offerCode = _self.data('offer-code');
        const beginDate = _self.data('start-date');
        const durata = _self.data('durata');
        const lordo = _self.data('gross-interest-rate');
        const netto = _self.data('net-interest-rate');

        $.ajax({
          'url': prestitoObj.context + `/socialLoanServlet/?operation=insertPromemoriaPianoFuturo&offerCode=${offerCode}&beginDate=${beginDate}&durata=${durata}&lordo=${lordo}&email=${email}&netto=${netto}`,
          'method': 'POST',
          'target': 'body',
          'success': function() {
            const email = emailP || prestitoObj.email;
            $('.alertify .icon-close').trigger('click');
            application.notify("<p class='h3'><strong>Fatto!</strong></p><p>Ti invieremo una email all'indirizzo <strong>" + email + "</strong> non appena sarà possibile attivare il piano che hai scelto.</p>", "success", "", 10000);          },
            'error': function() {
            $('.alertify .icon-close').trigger('click');
            application.notify("<p class='h3'><strong>Errore</strong></p><p>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile completare l'operazione. Riprova più tardi.</p>", 'error', undefined, 5000);
          }
        });
      }

      const self = $(this).parents('.prestitoVincolato__simulatorePiano').find('[data-avvisami-piano-futuro]');
      if(prestitoObj.user_logged != "true") {
        
        $('.alertify .icon-close').trigger('click');

        application.confirm(
          `<div class='container-generic-structure invia-dettagli-modal'>
            <div class='textContainer'>
              <p>Inserisci qui sotto l’indirizzo email a cui inviare questi dettagli. Non verrà in alcun modo registrato nei nostri archivi e sarà utilizzato solo ai fini di questo servizio.</p>
              <div class="col-md-8 col-md-offset-2 col-sm-12">
                
                <div class="input-container">
                  <div class="input-text required marLeft45">
                    <label for="email"></label>              
                      <i class="leftIcon icon-mail2"></i>
                      <input 	type="email"
                        id="email"
                        name="email"
                        value=""
                        placeholder="Inserisci il tuo indirizzo email" 
                      />
                  </div>
                </div>

                <div class="center_captcha no-padding">
                  <div id="captcha"></div>
                </div>

              </div>
            </div>
          </div>`,
          "Invia dettagli di questo Piano di risparmio via email",
          function() {
            servletPromemoriaPianoFuturo($('.alertify #email').val(), self);
          },
          function() {},
          "Invia",
          "Annulla",
        );

        $('.alertify nav .ok').addClass('disabled').attr('disabled', true);
        $('.alertify .input-text label').text("TUO INDIRIZZO EMAIL");
        
        const checkFormValidation = () => {
          if($('#email').val().length > 0 && inviaDettagliCaptcha) {
            $('.alertify nav .ok').removeClass('disabled').attr('disabled', false);
          }
        }  

        grecaptcha.render("captcha", {sitekey: $('[data-keys-site-captcha]').data('keys-site-captcha'), theme: "light", callback: function() {
          inviaDettagliCaptcha = true;
          checkFormValidation();
        }});


        $(document).on('input', '#email', function() {
          checkFormValidation();
        });
      } else {
        servletPromemoriaPianoFuturo("", self);
      }



    });




    if($('[data-dettaglio-piano]').length > 0) {

      $(document).on('click', '[data-dettaglio-piano]', function() {
          const successLink = $(this).data('m-link-more-info');
          const _self = $(this);
          const info = $(this).data('m-moadal-info');
          const flagTassoVariabile = $(this).data('m-flag-tasso-variabile');
          const tableToShow = flagTassoVariabile == 'S' ? $('[data-modale-dettaglio-incrementale]').html() :`
          <div class="my-row">
            <div class="">
              <p>Durata del vincolo</p>
              <p class="value-dettaglio"><strong>${$(this).data('m-durata')} mesi</strong></p>
            </div>

            <div class="">
              <p>Interesse lordo</p>
              <p class="value-dettaglio"><strong>${$(this).data('m-interesse-lordo')} %</strong></p>
            </div>

            <div class="">
              <p>Interesse netto *</p>
              <p class="value-dettaglio"><strong>${$(this).data('m-interesse-netto')} %</strong></p>
            </div>
          </div>`

          application.confirm(
            `<div class='container-generic-structure modale-dettaglio-piano'>
                <p class="value-dettaglio">${$(this).data('m-offerta-valida')}</p>
                ${tableToShow}
                <div class="dettaglio_info_download">
                  <span class="blue">*</span> ${info}
                </div>
                <div class="dettaglioPiano__info">
                  ${$(this).data('m-text')}
                </div>
            </div>`,
            $(this).data('m-title'),
            function() {
              if(_self.data('m-sottoscrivi').toLowerCase() == "avvisami") {
                
                const servletPromemoriaPianoFuturo = (emailP, _self) => {
                  const offerCode = _self.data('m-offer-code');
                  const beginDate = _self.data('m-begin-date');
                  const durata = _self.data('m-durata');
                  const lordo = _self.data('m-interesse-lordo');
                  const netto = _self.data('m-interesse-netto');

                  $.ajax({
                    'url': prestitoObj.context + `/socialLoanServlet/?operation=insertPromemoriaPianoFuturo&offerCode=${offerCode}&beginDate=${beginDate}&durata=${durata}&lordo=${lordo}&email=${email}&netto=${netto}`,
                    'method': 'POST',
                    'target': 'body',
                    'success': function() {
                      const email = emailP || prestitoObj.email;
                      $('.alertify .icon-close').trigger('click');
                      application.notify("<p class='h3'><strong>Fatto!</strong></p><p>Ti invieremo una email all'indirizzo <strong>" + email + "</strong> non appena sarà possibile attivare il piano che hai scelto.</p>", "success", "", 10000);
                    },
                    'error': function() {
                      $('.alertify .icon-close').trigger('click');
                      application.notify("<p class='h3'><strong>Errore</strong></p><p>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile completare l'operazione. Riprova più tardi.</p>", 'error', undefined, 5000);                    }
                  });
                }

                if(prestitoObj.user_logged != "true") {
                  
                  $('.alertify .icon-close').trigger('click');

                  application.confirm(
                    `<div class='container-generic-structure invia-dettagli-modal'>
                      <div class='textContainer'>
                        <p>Inserisci qui sotto l’indirizzo email a cui inviare questi dettagli. Non verrà in alcun modo registrato nei nostri archivi e sarà utilizzato solo ai fini di questo servizio.</p>
                        <div class="col-md-8 col-md-offset-2 col-sm-12">
                          
                          <div class="input-container">
                            <div class="input-text required marLeft45">
                              <label for="email"></label>              
                                <i class="leftIcon icon-mail2"></i>
                                <input 	type="email"
                                  id="email"
                                  name="email"
                                  value=""
                                  placeholder="Inserisci il tuo indirizzo email" 
                                />
                            </div>
                          </div>

                          <div class="center_captcha no-padding">
                            <div id="captcha"></div>
                          </div>

                        </div>
                      </div>
                    </div>`,
                    "Invia dettagli di questo Piano di risparmio via email",
                    function() {
                      servletPromemoriaPianoFuturo($('.alertify #email').val(), _self);
                    },
                    function() {},
                    "Invia",
                    "Annulla",
                  );

                  $('.alertify nav .ok').addClass('disabled').attr('disabled', true);
                  $('.alertify .input-text label').text("TUO INDIRIZZO EMAIL");
                  
                  const checkFormValidation = () => {
                    if($('#email').val().length > 0 && inviaDettagliCaptcha) {
                      $('.alertify nav .ok').removeClass('disabled').attr('disabled', false);
                    }
                  }  

                  grecaptcha.render("captcha", {sitekey: $('[data-keys-site-captcha]').data('keys-site-captcha'), theme: "light", callback: function() {
                    inviaDettagliCaptcha = true;
                    checkFormValidation();
                  }});


                  $(document).on('input', '#email', function() {
                    checkFormValidation();
                  });
                } else {
                  servletPromemoriaPianoFuturo("", _self);
                }
                
              } else {
                window.open(_self.data('m-sottoscrivi-piano'),  "_self");
              }
            },
            function() {
              window.open(successLink, "_self");
            },
            $(this).data('m-sottoscrivi'), //todo luca "avvisami o sottoscrivi"
            $(this).data('m-more-info')
          );

          if($(this).data('m-sottoscrivi-piano-controllo') == "" || $(this).data('m-controllo-s') == "true" || $(this).data('m-controllo-s') == true) {
            $(".alertify .dialog nav").remove();
          }
    
          $(".alertify .dialog").addClass("managecards800 nav");
          $('.alertify').addClass('overflow-scroll-modal');
          $('.alertify .dialog .cancel').remove();

          if($(this).parents('.socialLoan').find('.simulatore__btnContainer .btn-primary').hasClass('disabled')) {
            $('.alertify .dialog .ok').remove();
          }

    
      });

    }

    if($('.checkDisponibilita').length > 0) {
    var linkToModal = $( ".checkDisponibilita" );

        linkToModal.on("click", function() {
            let title = $(this).data('title');
            let modalita = $(this).data('modalita');
            let disponibilita = $(this).data('disponibilita');
            let updateDate = $(this).data('update-date');
            let normalTextSuff = $(this).data('normal-testo-suff');
            let normalTextInsuff = $(this).data('normal-testo-insuff');
            let newTextSuff = $(this).data('new-testo-suff');
            let newTextInsuff = $(this).data('new-testo-insuff');
            let newTextNodeposito = $(this).data('new-testo-nodeposito');
            let giacenza = $(this).data('giacenza');
            let labelPdf = $(this).data('label-pdf');
            let pdf = $(this).data('pdf');
            let showedText;
            let errorMessagge ="";
            let disponibilitaNumber;
            // check if disponibilita has comma 

            //disponibilita = 1900;
            if (typeof disponibilita != 'number' && disponibilita.includes(',') && disponibilita.includes('.')) {
                disponibilitaNumber = parseFloat(disponibilita.replace(',','.').replace('.',''))
            } 
            else if (typeof disponibilita != 'number' && disponibilita.includes(',')) {
                disponibilitaNumber = parseFloat(disponibilita.replace(',','.'))
            }
            else if (typeof disponibilita != 'number' && disponibilita.includes('.')) {
                disponibilitaNumber = parseFloat(disponibilita.replace('.',''))
            }
            else {
                disponibilitaNumber = disponibilita
            }

            if(typeof giacenza != 'number' && giacenza.includes('.') ) {
                giacenza = giacenza.replace('.','');
            }

            if (modalita == "N" && disponibilitaNumber >= 0) {
                showedText = normalTextSuff;
            }  else if (modalita == "N" && disponibilitaNumber < 0) {
                showedText = normalTextInsuff;
            } else if (modalita == "S" && giacenza < 1000) {
                showedText = newTextNodeposito;
                disponibilitaNumber = giacenza;
                errorMessagge = `L'importo attualmente disponibile sul tuo Prestito non consente di effettuare nuovi versamenti di almeno 1.000€ utili per sottoscrivere questo Piano.`;
            } else if (modalita == "S" && disponibilitaNumber >= 1000) {
                showedText = newTextSuff;
            } else if (modalita == "S" && disponibilitaNumber < 1000) {
                showedText = newTextInsuff;
                errorMessagge = "Al saldo attuale non hai una disponibilità vincolabile sufficiente per questo Piano."
            } else {
                showedText = "rip";
            }

            if($(this).closest('.prestitoVincolato__simulatorePiano').find('.simulator__error').length > 0) {
                errorMessagge = $(this).closest('.prestitoVincolato__simulatorePiano').find('.simulator__error').text();
            }

            let italianFormat = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' });
            let euro = italianFormat.format(Math.floor(disponibilitaNumber/1000)*1000);

            let errorMessaggeBuild = `<div class="wrap_error">
                                    <span class="error">
                                    <i class="icon-info-tooltip"></i></small>
                                    ${errorMessagge}</span>
                                </div>`
            
            application.confirm(
                `<div class='container-generic-structure'>
                ${disponibilitaNumber < 1000 ? "" : `<p class="centered sub_header"><b>Puoi vincolare complessivamente fino a</b></p>`}
                    <div class="wrap_disp">
                        <span class="disp">${euro}</span>
                        <span><strong>${updateDate}</strong></span>
                    </div>
                    ${errorMessagge !=  "" || errorMessagge ? errorMessaggeBuild : ""}
                    <div class="wrap_text">
                        <span>${showedText}</span>
                    </div>
                </div>
                `,
                title,
                function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    $('<a>',{
                        text: 'download',
                        href: pdf,
                        target: '_blank',
                        id: 'pdfLink',
                    }).appendTo('body');
                    $('#pdfLink')[0].click();
                    $('#pdfLink').remove();
                }
                ,
                function () {
                }
                ,
                labelPdf,
                "",
            );
            $('.alertify').addClass('check_disponibilita');
            $('.check_disponibilita .cancel').remove();
            $('.check_disponibilita nav').hide();

            if (modalita == "S" && giacenza < 1000) {
                $('.check_disponibilita nav').show();
                $('.wrap_text').css('border-top', '1px solid #E5E1E1');

            }

            // if se non puoi versare nuove liquidità 


        });
    }

    if($('[data-simula-interesse]').length > 0) {
      $(document).on('click', '[data-simula-interesse]', function() {
        $(this).parents('[data-component="interestCalculatorComponent"]').addClass('simulaInteresse_open');
        $(this).addClass('hide');
        $(this).parents('[data-component="interestCalculatorComponent"]').find('[data-close-simula-interesse]').removeClass('hide');

        if($(this).parents('[data-component="interestCalculatorComponent"]').find('.tasso_variabile').length > 0) {
          if(application.isMobile() || application.isTablet()) {
            $(this).parents('[data-component="interestCalculatorComponent"]').find('[data-nav="1"]').click();
            $(this).parents('[data-component="interestCalculatorComponent"]').find('[data-nav="0"]').click();

            $(window).resize();

          }
        }
      });

      $(document).on('click', '[data-close-simula-interesse]', function() {
        $(this).parents('[data-component="interestCalculatorComponent"]').removeClass('simulaInteresse_open');
        $(this).addClass('hide');
        $(this).parents('[data-component="interestCalculatorComponent"]').find('[data-simula-interesse]').removeClass('hide');
      });

      application.sliderSimulator();

      if($('.sliderVincolato').length > 0) {


        $(document).on('slide change', '.sliderVincolato', function(event) {
          let importo = 0;
          if(event.value.newValue) {
            importo = event.value.newValue;
          } else {
            importo = event.value;
          }
          let lordoStimato;
          let interesseNetto;

          let flagTassoVariabile = $(this).parents('.prestitoVincolato__simulatorePiano').find('.interestTable__new').data('flag-tasso-variabile');

          if(flagTassoVariabile == 'S') {
              $('.interestTable__new.tasso_variabile .wrap_tassi').each(function() {
                  let tassoNetto = $(this).find('.netInterest').data('net-interest');
                  let tassoLordo = $(this).find('.grossInterest').data('gross-interest');
                  let tassoLordoStimato = ((importo * tassoLordo / 100)).toLocaleString(undefined, { minimumFractionDigits: 2 });
                  let tassoNettoStimato = ((importo * tassoNetto / 100)).toLocaleString(undefined, { minimumFractionDigits: 2 });
                  $(this).find('.grossInterestAmount span').text(tassoLordoStimato);
                  $(this).find('.netInterestAmount span').text(tassoNettoStimato);
              });
             

              //interesse lordo
              $(this).parents('.prestitoVincolato__simulatorePiano').find('.interestTable__new .grossInterestAmount span').text(lordoStimato);
              //interesse netto
              $(this).parents('.prestitoVincolato__simulatorePiano').find('.interestTable__new .netInterestAmount span').text(interesseNetto);
          } else {
              lordoStimato = ((importo * +$(this).parents('.prestitoVincolato__simulatorePiano').find('.grossInterest').data('gross-interest') / 100) * (+$(this).parents('.prestitoVincolato__simulatorePiano').find('.grossInterestAmount').data('period-val') / 12) ).toLocaleString(undefined, { minimumFractionDigits: 2 });  
              interesseNetto = ((importo * +$(this).parents('.prestitoVincolato__simulatorePiano').find('.netInterest').data('net-interest') / 100) * (+$(this).parents('.prestitoVincolato__simulatorePiano').find('.grossInterestAmount').data('period-val')  / 12)).toLocaleString(undefined, { minimumFractionDigits: 2 });

              //interesse lordo
              $(this).parents('.prestitoVincolato__simulatorePiano').find('.interestTable__new .grossInterestAmount span').text(lordoStimato);
              //interesse netto
              $(this).parents('.prestitoVincolato__simulatorePiano').find('.interestTable__new .netInterestAmount span').text(interesseNetto);
          }



          //per rendere sempre visibile il tooltip
          $(this).parents('.prestitoVincolato__simulatorePiano').find('.tooltip.tooltip-main.top').addClass('tooltip__alwaysOn top');

        });

        $(document).on('click', '[data-sottoscrivi-questo-piano]', function(event) {          
          const amount = $(this).parents('.prestitoVincolato__simulatorePiano').find('.sliderVincolato input').attr('value');
          const link = $(this).data('sottoscrivi-questo-piano');
          window.open(link + amount, "_self");
        });
      }

    }



    if ($('[data-invia-dettagli-email]').length > 0) {

      const inviaDettagliServlet = (email, offerCode, grossInterestRate, netInterestRate, duration) => {
        //servlet che manda email
        

        $.ajax({
          'url': prestitoObj.context + `/socialLoanServlet/?operation=sendDetailPianoVincolato&offerCode=${offerCode}&grossInterestRate=${grossInterestRate}&netInterestRate=${netInterestRate}&duration=${duration}&email=${email}&beginDate=${$('[data-start-date]').data('start-date')}`,
          'method': 'POST',
          'target': 'body',
          'success': function (res) {
              if(res){
                application.notify("<h3><strong>Operazione riuscita.</strong></h3><p class='h4'>Controlla la tua casella email, ti abbiamo inviato i dettagli di questo Piano vincolato.</strong></p>", "success", "", 10000);
              } else{
                application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);
              }
           },
          'error': function () {
              application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);      
           }
        });
      }

      $(document).on('click', '[data-invia-dettagli-email]', function () {

        const offerCode = $(this).data('offer-code');
        const grossInterestRate = $(this).data('gross-interest-rate');
        const netInterestRate = $(this).data('net-interest-rate');
        const duration = $(this).data('durata');

        if ($(this).data('invia-dettagli-email') == "socio") {
          inviaDettagliServlet("", offerCode, grossInterestRate, netInterestRate, duration);

        } else {

          $('.alertify .icon-close').trigger('click');

          application.confirm(
            `<div class='container-generic-structure invia-dettagli-modal'>
                <div class='textContainer'>
                  <p>Inserisci qui sotto l’indirizzo email a cui inviare questi dettagli. Non verrà in alcun modo registrato nei nostri archivi e sarà utilizzato solo ai fini di questo servizio.</p>
                  <div class="col-md-8 col-md-offset-2 col-sm-12">
                    
                    <div class="input-container">
                      <div class="input-text required marLeft45">
                        <label for="email"></label>              
                          <i class="leftIcon icon-mail2"></i>
                          <input 	type="email"
                            id="email"
                            name="email"
                            value=""
                            placeholder="Inserisci il tuo indirizzo email" 
                          />
                      </div>
                    </div>

                    <div class="center_captcha no-padding">
                      <div id="captcha"></div>
                    </div>

                  </div>
                </div>
              </div>`,
            "Invia dettagli di questo Piano di risparmio via email",
            function () {
              inviaDettagliServlet($('.alertify #email').val(), offerCode, grossInterestRate, netInterestRate, duration);
            },
            function () { },
            "Invia",
            "Annulla",
          );

          $('.alertify nav .ok').addClass('disabled').attr('disabled', true);
          $('.alertify .input-text label').text("TUO INDIRIZZO EMAIL");

          const checkFormValidation = () => {
            if ($('#email').val().length > 0 && inviaDettagliCaptcha) {
              $('.alertify nav .ok').removeClass('disabled').attr('disabled', false);
            }
          }

          grecaptcha.render("captcha", {
            sitekey: $('[data-keys-site-captcha]').data('keys-site-captcha'), theme: "light", callback: function () {
              inviaDettagliCaptcha = true;
              checkFormValidation();
            }
          });


          $(document).on('input', '#email', function () {
            checkFormValidation();
          });

        }
      });
    }

  }

  this.sliderSimulator = function() {

    if( $("#sliderSavings input").length){

      var mySlider = $("#sliderSavings input").bootstrapSlider();

      $('.tooltip.tooltip-main').addClass('tooltip__alwaysOn top');
      $('.tooltip.tooltip-main .arrow').addClass('tooltip-arrow');


      var dataGrossInterest=0;
      var dataNetInterest=0;
      var dataPercentageObtain=0;
      var grossInterestAmount=$(".grossInterestAmount span");
      var netInterestAmount=$(".netInterestAmount span");

      if ($("[data-gross-interest]").length){

        dataGrossInterest=$("[data-gross-interest]").attr("data-gross-interest");

      }

      if ($("[data-net-interest]").length){

        dataNetInterest=$("[data-net-interest]").attr("data-net-interest");

      }

      if ($("[data-percentage-obtain]").length){

        dataPercentageObtain=$("[data-percentage-obtain]").attr("data-percentage-obtain");

      }

      var period;
      if ($("[data-period-val]").length){

        period=$("[data-period-val]").attr("data-period-val");

      }

      period = parseInt(period)/12;

      //set the selected value
      /* mySlider.on('slide change', function(event) {

        var value = mySlider.bootstrapSlider('getValue');

        $(".calculatedAmount em").text("€ "+application.numformatter.numberFormatter(value,"it"));

        if ($("[data-calculated-amount]").length){

          $("[data-calculated-amount]").attr("data-calculated-amount",value);

          if(grossInterestAmount.length && netInterestAmount.length){

            var interesse_lordo = (value * period * parseFloat(dataGrossInterest.replace(',', '.')) / 100);
            grossInterestAmount.text("€ "+application.numformatter.numberFormatter( interesse_lordo.toFixed(2),"it"));

            var interesse_netto = interesse_lordo - (interesse_lordo * parseFloat(dataPercentageObtain.replace(',', '.')) / 100);
            netInterestAmount.text("€ "+interesse_netto.toFixed(2),"it");

          }

        }

        constrainedLoan.calculateBalance();

      }); */

      //refresh tooltip of the slider
      $('[data-target="#simulator-plan"]').on('click', function(event) {

        setTimeout(function(){

          mySlider.bootstrapSlider('relayout');

        }, 300);

        //set the new value to the slider
        if( $("#calculatorSlider").length && $("#calculatorSlider").val()!=="" && $(this).hasClass('btn') ){

          var selectedValue = parseInt($("#calculatorSlider").val());
          mySlider.bootstrapSlider('setValue', selectedValue, true, true );

        }

      });

      //fix position of the slider ticks label
      $("#sliderSavings .slider-tick").each(function(index, el) {

        $("#sliderSavings .slider-tick-label-container div").eq(index).attr('style', $(this).attr("style")).show();

      });

    }


  };

   /**
     * Setup Lazy Load XT
     *
     */
    this.setupLazyLoad = function () {

        // check app started
        if (application.appStart) return;

        // log
        application.console("Lazy Load Detected!");

        // styles to load
        var styles = {

            "lazyloadxt/css/jquery.lazyloadxt.fadein": "lib"

        };
        application.loadStyle(styles);

        // scripts to load
        var scripts = {

            "lazyloadxt/js/jquery.lazyloadxt.extra": "lib"

        };

        application.loadScript(scripts, function () {
          var LAZY_LOAD_START = false;

          $.extend($.lazyLoadXT, {

              elementSelector: $.lazyLoadXT.elementSelector + ",[data-bg],[data-bgmob]",
              //forceLoad: "true",
              onshow: function (e) {
                  LAZY_LOAD_START = true;
              }

            });

            msieversion = function () {
              var ua = window.navigator.userAgent;
              var msie = ua.indexOf('Trident/');
      
              if (msie > 0) {
                  return true;
              }
      
              return false;
            };

            //load backgrounds when they enter the viewport
            $('.lazy-loaded').on('lazyshow', function () {
                loadBg($(this));
            });

            if (!LAZY_LOAD_START && msieversion()) {
                $('.lazy-loaded').each(function () {
                    loadBg($(this));
                });
            }

            function loadBg(selector) {
                var bg = selector.attr("data-bg");
                var bgmob = selector.attr("data-bgmob");
                if (application.isMobile()) {

                    if ((bgmob !== "") && (bgmob != undefined) && (bgmob != "")) {

                        selector.css("background-image", 'url(' + bgmob + ')').triggerHandler('load');

                    }

                } else {

                    if ((bg !== "") && (bg !== null) && (bg !== undefined)) {

                        selector.css("background-image", 'url(' + bg + ')').triggerHandler('load');

                    }

                }
            }

        });

    };

    /**
     * Setup Tiny Slider
     * 
     */
    this.setupTinySlider = function () {
              // check app started
              if (application.appStart) return;
              // log
              application.console("Tiny Slider Detected!");
      
              // styles to load
              var styles = {
      
                  "tiny-slider/css/tiny-slider": "lib"
      
              };
              application.loadStyle(styles);
      
              // scripts to load
              var scripts = {
      
                  "tiny-slider/js/tiny-slider": "lib",
      
              };

              application.loadScript(scripts, function () {
                var tinySliderContent = this;
                var application = Prestito.Application.getInstance();
                var device = sessionStorage.getItem("curDevice");

                init();

                /**
                 * Init component
                 *
                 */
                function init() {
                    // log
                    application.console.log("Init component:TINY  SLIDER CONTENT");
                    // check app started
                    if (application.appStart) return;

                    $(document).on("deviceChanged", function () {
                        device = sessionStorage.getItem("curDevice");

                        $(".tinySlider").each(function (index, el) {
                          if(application.isMobile() || application.isTablet()){
                              $(el).children("[data-type-component='decidoAncheIoProjectPlaceholder']").remove();
                          }
                          initSliders(el, index);
                        });
                    });

                    $(".tinySlider").each(function (index, el) { 
                      if(application.isMobile() || application.isTablet()){
                          $(el).children("[data-type-component='decidoAncheIoProjectPlaceholder']").remove();
                      }
                        initSliders(el, index);
                    });
                };

                /* --------------------- */

                /*
                * initSliders
                */
                function initSliders(el, i) {
                    var options = "";

                    var sliderType = "default";

                    //get the number of slides
                    var numSlides = $(el).children().length;
                    if ($(el).data("slidertype")) {
                        if ($(el).data("slidertype") !== "") {
                            sliderType = $(el).data("slidertype");
                        }
                    }

                    let data_item_d = $(el).data("item-d")||2;
                    let data_item_t = $(el).data("item-t")||2;
                    let data_item_m = $(el).data("item-m")||1;

                    let data_gutter_d = $(el).data("gutter-d")||15;
                    let data_gutter_t = $(el).data("gutter-t")||15;
                    let data_gutter_m = $(el).data("gutter-m")||1;

                    let data_controls_d = true;
                    if($(el).data("controls-d") != undefined) {
                      data_controls_d = $(el).data("controls-d");
                    }
                    let data_controls_t = true;
                    if($(el).data("controls-t") != undefined) {
                      data_controls_t = $(el).data("controls-t");
                    }

                    let data_center_m = false;
                    if ($(el).data("center-m") != undefined) {
                      data_center_m = $(el).data("center-m");
                    }

                    if (numSlides > 1) {
                        switch (sliderType) {
                            case "mobileOnly":
                                if (!el.classList.contains("tns-slider")) {
                                    if (device.indexOf("isDesktop") == -1) {
                                        options = {
                                            container: el,
                                            items: data_item_m,
                                            gutter: 15,
                                            edgePadding: 15,
                                            loop: false,
                                            mouseDrag: true,
                                            navPosition: "bottom",
                                            controls: false,
                                            autoHeight: true,
                                            preventScrollOnTouch: "force",
                                            swipeAngle: 50,
                                            responsive: {
                                                768: {
                                                    items: data_item_t,
                                                    gutter: 15
                                                }
                                            },
                                        };
                                    }
                                } else {
                                    if (device.indexOf("isDesktop") > -1) {
                                        try {
                                            window["tinySlider_" + i].destroy();
                                        } catch {
                                            console.log("no slider?");
                                        }
                                    }
                                }
                                break;
                            case "desktopOnly":
                              if (!el.classList.contains("tns-slider")) {
                                  options = {
                                      container: el,
                                      items: data_item_m,
                                      gutter: 15,
                                      loop: false,
                                      mouseDrag: true,
                                      navPosition: "bottom",
                                      controls: true,
                                      autoHeight: true,
                                      preventScrollOnTouch: "force",
                                      swipeAngle: 50,
                                      controlsPosition: "center",
                                      controlsText: ['<i class="icon-arrow-left-1"></i>', '<i class="icon-arrow-right-1"></i>'],
                                      responsive: {
                                          768: {
                                              items: data_item_t,
                                          },
                                          1024: {
                                              items: 4,
                                          },
                                      },
                                  };
                              }
                                break;
                            default:
                              if (!el.classList.contains("tns-slider")) {
                                  options = {
                                      container: el,
                                      items: data_item_m,
                                      gutter: data_gutter_m,
                                      loop: false,
                                      center: data_center_m,
                                      mouseDrag: true,
                                      navPosition: "bottom",
                                      controls: $('[data-type-component="documentiAreaRiservata"]').length > 0 || $('.dashboardFavouriteStore ').length > 0 ? true : false,
                                      preventScrollOnTouch: "force",
                                      autoHeight: $('[data-type-component="documentiAreaRiservata"]').length > 0 ? true : false,
                                      swipeAngle: 50,
                                      controlsPosition: "center",
                                      controlsText: ['<i class="icon-arrow-left-1"></i>', '<i class="icon-arrow-right-1"></i>'],
                                      responsive: {
                                          320: {
                                              items: data_item_m,
                                              gutter: data_gutter_m,
                                              center: data_center_m,
                                              controls: $('.singleTouchpointComponent').length > 0 || $('.dashboardFavouriteStore ').length > 0 || $('[data-type-component="documentiAreaRiservata"]').length > 0 ? true : false
                                          },
                                          768: {
                                              items: data_item_t,
                                              gutter: data_gutter_t,
                                              center: false,
                                              controls: data_controls_t
                                          },
                                          1024: {
                                              items: data_item_d,
                                              gutter: data_gutter_d,
                                              center: false,
                                              controls: data_controls_d
                                          },
                                      },
                                  };

                              }
                        }

                        if (options !== "") {
                            window["tinySlider_" + i] = tns(options);
                        }
                    }

                    if ($(el).hasClass('flexSlider')) {
                      if($(el).attr("data-type-component")==="decidoAncheIoDashboardSLider"){
                          $(el).css({'display':'flex','justify-content':'center'});
                      } else if(($(el).attr("data-type-component")==="iTuoiNegoziDashboardSlider")) {
                          if(application.isMobile()) {
                              //$(el).css({'display':'flex'});
                          } else{
                              //$(el).css({'display':'flex','justify-content':'center'});
                          }
                      } else { 
                          $(el).css({'display':'flex','margin':'0 auto'});
                      }
                    } else {
                      $(el).show();
                    }
                };

            });
    }

  this.SliderContent = function () {

    /**
     * Local variables
     *
     */
    var sliderContent = this;
    var application = Prestito.Application.getInstance();

    /**
     * Init component
     *
     */
    styles = {
      "lightslider/css/lightslider": "lib"
    };
    application.loadStyle(styles);

    // scripts to load
    scripts = {
      "lightslider/js/lightslider": "lib",
    };

    application.loadScript(scripts, function () {



      // log
      application.console.log("Init component: SLIDER CONTENT");
      // check app started
      if (application.appStart) return;

      //save instance of sliders to destroy later
      sliderContent.sliders = [];

      startSliderInit();



      function startSliderInit(type_slider) {
        // slider components
        var sliderComponents = $("*[data-component=sliderContentComponent]");
        function switchSlider(typeOfSlider, sliderTypeComponent) {
          switch (typeOfSlider) {

            case "bgImg":

              bgImgSlider(sliderTypeComponent);

              break;

            case "flyers":

              flyersSlider(sliderTypeComponent);

              break;

            case "flyers2021":

              flyers2021Slider(sliderTypeComponent);

              onResizeSlider(sliderTypeComponent, function () {
                if (!application.isMobile()) {
                  flyers2021Slider(sliderTypeComponent);
                }
              });

              break;

            case "storeFlyers":

              storeFlyersSlider(sliderTypeComponent);

              break;

            case "ourServices":

              ourServicesSlider(sliderTypeComponent);

              break;

            case "genericBoxes":

              genericBoxesSlider(sliderTypeComponent);

              break;

            case "documentiAreaRiservata":

              documentiAreaRiservata(sliderTypeComponent);

              break;


            case "projects":

              projectsSlider(sliderTypeComponent);

              break;

            case "projectPartners":

              projectPartnersSlider(sliderTypeComponent);

              break;

            case "genericPageCarousel":

              genericPageCarouselSlider(sliderTypeComponent);

              break;

            case "artworksLastScores":

              artworksLastScoresSlider(sliderTypeComponent);

              break;

            case "artworksScoreList":

              artworksScoreListSlider(sliderTypeComponent);

              break;

            case "donut":

              donutSlider(sliderTypeComponent);


              break;

            case "gallery":

              gallerySlider(sliderTypeComponent);

              break;

            case "dashboardBox":

              dashboardBoxSlider(sliderTypeComponent);

              break;

            case "contributeProjects":

              contributeProjectsSlider(sliderTypeComponent);

              break;

            case "electionList":

              electionListSlider(sliderTypeComponent);

              break;

            case "flyersSearch":

              flyersSearchSlider(sliderTypeComponent);

              break;

            case "flyersStoreMap":

              flyersStoreMapSlider(sliderTypeComponent);

              break;

            case "newFaqs":

              newFaqsSlider(sliderTypeComponent);

              break;

            case "artworksWinnerLastYear":

              artworksWinnerLastYearSlider(sliderTypeComponent);

              break;

            case "conventionsDigital":

              conventionsDigitalSlider(sliderTypeComponent);

              break;

            case "ambitDigital":

              ambitDigitalSlider(sliderTypeComponent);

              break;

            case "storeConventionsDigital":

              storeConventionsDigitalSlider(sliderTypeComponent);

              break;


            case "interestSlider":

              interestSlider(sliderTypeComponent);

              break;

            case "promoConventions":

              promoConventionsSlider(sliderTypeComponent);

              break;

            case "homeListProjects":

              homeListProjectsSlider(sliderTypeComponent);

              break;

            case "promosStoreMap":

              promosStoreMapSlider(sliderTypeComponent);

              break;

            case "openingHours":

              openingHoursSlider(sliderTypeComponent);

              break;

            case "newStoreFlyers":

              if (!application.isMobile()) {
                newStoreFlyersSlider(sliderTypeComponent);
              }

              onResizeSlider(sliderTypeComponent, function () {
                if (!application.isMobile()) {
                  newStoreFlyersSlider(sliderTypeComponent);
                }
              });

              break;

            case "partnerPointPromotions":

              partnerPointPromotions(sliderTypeComponent);

              onResizeSlider(sliderTypeComponent, function () {
                partnerPointPromotions(sliderTypeComponent);
              });

              break;

            case "flyersHeader":

              flyersHeaderSlider(sliderTypeComponent);

              break;

            case "promoHome":

              promoHomeSlider(sliderTypeComponent);

              break;

            case "coopXtePromo":

              if (application.isMobile() || application.isTablet()) {
                coopXtePromo(sliderTypeComponent);
              }

              onResizeSlider(sliderTypeComponent, function () {
                if (application.isMobile() || application.isTablet()) {
                  coopXtePromo(sliderTypeComponent);
                }
              });

              break;

            case "coopXteHomePromo":

              coopXteHomePromo(sliderTypeComponent);

              onResizeSlider(sliderTypeComponent, function () {
                coopXteHomePromo(sliderTypeComponent);
              });

              break;

            case "coopXteLandingPromo":

              coopXteLandingPromo(sliderTypeComponent);

              onResizeSlider(sliderTypeComponent, function () {
                coopXteLandingPromo(sliderTypeComponent);
              });

              break;

            case "offersDiscountPromotions":

              if ((application.isMobile()) || (application.isTablet())) {
                offersDiscountPromotions(sliderTypeComponent);
              }

              onResizeSlider(sliderTypeComponent, function () {
                if ((application.isMobile()) || (application.isTablet())) {
                  offersDiscountPromotions(sliderTypeComponent);
                }
              });

              break;

            case "digitalKdSlider":
            case "digitalLeafletKdSlider":

              digitalKdSlider(sliderTypeComponent);

              onResizeSlider(sliderTypeComponent, function () {
                digitalKdSlider(sliderTypeComponent);
              });

              break;

            case "coopXtuttiXtePromo":

              if (application.isMobile() || application.isTablet()) {
                coopXtuttiXtePromo(sliderTypeComponent);
              }

              onResizeSlider(sliderTypeComponent, function () {
                if (application.isMobile() || application.isTablet()) {
                  coopXtuttiXtePromo(sliderTypeComponent);
                }
              });

              break;

          }
        }

        if (type_slider) {
          switchSlider(type_slider, $('[data-type-component="' + type_slider + '"]'));
          return;
        }

        // fetch components
        $.each(sliderComponents, function (index) {
          var sliderComponent = $(this);

          // type of slider
          var sliderTypeComponents = $('*[data-type-component]', sliderComponent);

          // fetch components
          $.each(sliderTypeComponents, function (index) {

            var sliderTypeComponent = $(this);

            if (sliderTypeComponent.attr('data-load') === 'false') {
              return;
            }

            var typeOfSlider = sliderTypeComponent.attr('data-type-component');

            switchSlider(typeOfSlider, sliderTypeComponent);

            if (type_slider === typeOfSlider) {
              return;
            }

          });

        });
      };

      this.onResizeSlider = function (element, callback) {
        let resizeId2;
        sliderContent.width = $(window).width();

        $(window).resize(function () {
          if ($(this).width() !== sliderContent.width) {
            sliderContent.width = $(this).width();
            clearTimeout(resizeId2);
            resizeId2 = setTimeout(function () {
              var sliderKD = sliderContent.sliders.filter((item) => {
                return item.elHTML == element;
              });

              if (sliderKD.length > 0) {
                sliderKD.map(function (item) {
                  return item.slider.destroy();
                });

                var sliderNoKD = sliderContent.sliders.filter((item) => {
                  return item.elHTML != element;
                });

                sliderContent.sliders = sliderNoKD;

                callback();
              } else {
                callback();
              }

            }, 400);
          }
        });
      };

      /* --------------------- */





      /**
       * Setup newFaqsSlider
       *
       */
      newFaqsSlider = function (element) {

        var options = {

          item: 5,
          autoWidth: false,
          adaptiveHeight: true,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-newfaqs',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          pager: false,
          freeMove: true,
          responsive: [{

            breakpoint: 1198,
            settings: {

              item: 5

            }

          }, {

            breakpoint: 990,
            settings: {

              item: 3

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1,
              pager: true,
              autoWidth: true

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };




      /**
       * Setup donut
       *
       */
      donutSlider = function (element) {

        //   if( $( element ).parents( ".sliderExploded" ).length > 0 ) return true;

        var options = {

          autoWidth: true,
          adaptiveHeight: true,
          loop: false,
          pager: true,
          currentPagerPosition: 'middle',
          speed: 400,
          addClass: 'hidePagerMobile',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 767,
            settings: {

              item: 1,
              slideMargin: 10

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });
      };

      /**
       * Setup dashboardBoxSlider
       *
       */
      dashboardBoxSlider = function (element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          item: 2,
          loop: false,
          slideMove: 1,
          slideMargin: 0,
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-2',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          responsive: [{
            breakpoint: 920,
            settings: {
              item: 2,
              controls: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              item: 1,
              controls: true
            }
          }
          ]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        $(element).find("img").on('lazyshow', function () {

          $(element).find("img").lazyLoadXT();


        });

        startSlider(element, options);

      };

      /**
       * Setup gallerySlider
       *
       */
      gallerySlider = function (element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          adaptiveHeight: false,
          slideMargin: 30,
          useCSS: false,
          item: 1,
          loop: false,
          pager: true,

          onAfterSlide: function (el) {


            $(window).resize();


          },
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-2',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              slideMargin: 30

            }

          }, {

            breakpoint: 767,
            settings: {

              slideMargin: 15

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };

      /**
       * Setup contributeProjectsSlider
       *
       */
      contributeProjectsSlider = function (element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: false,
          adaptiveHeight: false,
          slideMargin: 20,
          galleryMargin: 0,
          useCSS: false,
          item: 3,
          loop: false,
          pager: false,

          onAfterSlide: function (el) {


            $(window).resize();


          },
          currentPagerPosition: 'middle',
          addClass: 'controls-left-right-alt-2',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 920,
            settings: {

              item: 2

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        $(element).find("img").on('lazyshow', function () {

          $(element).find("img").lazyLoadXT();


        });

        startSlider(element, options);

      };

      /**
       * Setup projectPartnersSlider
       *
       */
      projectPartnersSlider = function (element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: false,
          adaptiveHeight: false,
          slideMargin: 20,
          galleryMargin: 30,
          useCSS: false,
          item: 6,
          loop: false,
          pager: false,

          onAfterSlide: function (el) {


            $(window).resize();


          },
          currentPagerPosition: 'middle',
          addClass: 'controls-left-right-alt-2',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 920,
            settings: {

              item: 3

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        $(element).find("img").on('lazyshow', function () {

          $(element).find("img").lazyLoadXT();


        });

        startSlider(element, options);

      };

      /**
       * Setup storeFlyersSlider
       *
       */
      storeFlyersSlider = function (element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          adaptiveHeight: true,
          slideMargin: 30,
          loop: false,
          pager: true,
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-2',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              slideMargin: 30

            }

          }, {

            breakpoint: 767,
            settings: {

              slideMargin: 15

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };

      /**
       * Setup ourServicesSlider
       *
       */
      ourServicesSlider = function (element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {
          item: 4,
          autoWidth: false,
          slideMargin: 0,
          loop: false,
          pager: false,
          addClass: 'controls-left-right',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 3

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }
        var sliderImgArray = [];
        var el = $(element)[0];
        $(window).scroll(function () {
          if (sliderContent.elementInViewport2(el)) {
            sliderContent.startSliderEasyLoad(element, options, sliderImgArray);
          }
        });

      };


      documentiAreaRiservata = function (element) {
        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          item: 3,
          autoWidth: false,
          adaptiveHeight: false,
          loop: false,
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          slideMargin: 15,
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 3

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 2

            }

          }, {

            breakpoint: 320,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = true;

        }

        startSlider(element, options);
      }

      /**
       * Setup genericBoxesSlider
       *
       */
      function genericBoxesSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          item: 4,
          autoWidth: false,
          adaptiveHeight: false,
          loop: false,
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          slideMargin: 15,
          controls: false,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 2

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = true;

        }

        startSlider(element, options);

      };

      /**
       * Setup projectsSlider
       *
       */
      function projectsSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          slideMargin: 15,
          loop: false,
          pager: true,
          currentPagerPosition: 'middle',
          addClass: 'controls-left-right-alt',
          galleryMargin: 15,
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 3

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };

      /**
       * Setup genericPageCarouselSlider
       *
       */
      function genericPageCarouselSlider(element) {
        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          item: 6,
          autoWidth: false,
          slideMargin: 0,
          loop: false,
          pager: false,
          addClass: 'controls-left-right',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 4

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 2

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };

      /**
       * Setup homeListProjectsSlider
       *
       */
      function homeListProjectsSlider(element) {

        var options = {

          item: 1.5,
          autoWidth: false,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-artworksLastScores',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 1

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };

      /**
       * Setup artworksWinnerLastYearSlider
       *
       */
      function artworksWinnerLastYearSlider(element) {

        var options = {

          item: 1.5,
          autoWidth: false,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-artworksLastScores',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 1

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };


      /**
       * Setup conventionsDigitalSlider
       *
       */
      function conventionsDigitalSlider(element) {

        var options = {

          item: 3,
          autoWidth: false,
          adaptiveHeight: true,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-conventionsDigital',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          pager: true,
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 2

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };



      /**
       * Setup ambitDigitalSlider
       *
       */
      function ambitDigitalSlider(element) {

        var options = {

          item: 6,
          autoWidth: true,
          adaptiveHeight: true,
          slideMargin: 0,
          loop: false,
          addClass: 'controls-ambitDigital',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          pager: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1198,
            settings: {

              item: 5

            }

          }, {

            breakpoint: 990,
            settings: {

              item: 4

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1,
              autoWidth: false

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };




      /**
       * Setup storeConventionsDigitalSlider
       *
       */
      function storeConventionsDigitalSlider(element) {

        var options = {

          item: 3,
          autoWidth: false,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-store-conventionsDigital',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 2

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };



      /**
       * Setup promoConventionsSlider
       *
       */
      function promoConventionsSlider(element) {

        var options = {

          item: 2,
          autoWidth: false,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-conventionsPromo',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 2

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };



      /**
       * Setup artworksLastScoresSlider
       *
       */
      function artworksLastScoresSlider(element) {

        var options = {

          item: 2,
          autoWidth: false,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-artworksLastScores',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 2

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };

      /**
       * Setup artworksScoreListSlider
       *
       */
      function artworksScoreListSlider(element) {

        var options = {

          item: 1,
          autoWidth: false,
          slideMargin: 15,
          loop: false,
          addClass: 'controls-artworksScoreList',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };


      /**
       * Setup Election list
       *
       */
      function electionListSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          item: 4,
          autoWidth: false,
          slideMargin: 0,
          loop: false,
          pager: false,
          addClass: 'controls-left-right',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: false,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 3

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1,
              enableDrag: true

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };

      /**
       * Setup flyersSearchSlider
       *
       */
      function flyersSearchSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          adaptiveHeight: true,
          slideMargin: 15,
          loop: false,
          pager: true,
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-3',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 767,
            settings: {

              item: 2,

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };

      /**
       * Setup flyersStoreMapSlider
       *
       */
      function flyersStoreMapSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {


          autoWidth: true,
          adaptiveHeight: true,
          slideMargin: 15,
          loop: false,
          pager: true,
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-3',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 767,
            settings: {

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };

      /**
       * Setup flyersHeaderSlider
       *
       */
      function flyersHeaderSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          adaptiveHeight: true,
          slideMargin: 15,
          loop: false,
          pager: false,
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-4',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 767,
            settings: {

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        var thisSlider = element;
        var checkPager = function () {

          thisSlider.width(thisSlider.width() + 1);//fix zoom
          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };

      /**
       * Setup promosStoreMapSlider
       *
       */
      function promosStoreMapSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          slideMargin: 15,
          loop: false,
          pager: true,
          currentPagerPosition: 'middle',
          addClass: 'controls-left-right-alt-3',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {


            }

          }, {

            breakpoint: 767,
            settings: {


            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };


      /**
       * Setup openingHoursSlider
       *
       */
      function openingHoursSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: false,
          slideMargin: 15,
          loop: false,
          pager: false,
          item: 2,
          slideMove: 1,
          addClass: 'controls-artworksScoreList',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              item: 1,

            }

          }, {

            breakpoint: 767,
            settings: {

              item: 1,

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

      };

      function partnerPointPromotions(element) {
        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          // autoWidth: true,
          adaptiveHeight: false,
          slideMargin: 15,
          loop: false,
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-2',
          controls: false,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          pager: true,
          //   enableDrag: true,
          enableTouch: true,
          freeMove: true,
          item: 2,
          responsive: [{

            breakpoint: 1023,
            settings: {
              autoWidth: true,
              item: 1,
              slideMargin: 0
            }


          }, {

            breakpoint: 767,
            settings: {
              autoWidth: true,
              item: 1,
              slideMargin: 0
            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        //hide the left arrow, at first
        $(".container-flyers-store .lSPrev").addClass("hide");

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });
      };


      /**
       * Setup newStoreFlyersSlider
       *
       */
      function newStoreFlyersSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          adaptiveHeight: false,
          slideMargin: 0,
          loop: false,
          pager: false,
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'leafletArrows',
          controls: true,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          onAfterSlide: function (el) {

            var sliderW = $('[data-type-component="newStoreFlyers"]');
            var offset = sliderW.offset();
            var totalSlider = sliderW.width();

            var sliderWrapperW = $('.container-flyers-store');
            var offset2 = sliderWrapperW.offset();
            var totalSliderWrapper = sliderWrapperW.width();

            var lastSlide = (totalSlider - totalSliderWrapper - offset2.left + offset.left);

            if (lastSlide < 1) {
              $(".container-flyers-store [data-component=sliderContentComponent] .lSNext").addClass("hide");
            } else {
              $(".container-flyers-store [data-component=sliderContentComponent] .lSNext").removeClass("hide");
            }

          },
          onBeforeSlide: function (el) {
            //hide/show the left arrow
            if (el.getCurrentSlideCount() == 1) {
              $(".container-flyers-store .lSPrev").addClass("hide");
            } else {
              $(".container-flyers-store .lSPrev").removeClass("hide");
            }

          },
          responsive: [{

            breakpoint: 1023,
            settings: {

              slideMargin: 15

            }

          }, {

            breakpoint: 767,
            settings: {

              slideMargin: 15

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        //hide the left arrow, at first
        $(".container-flyers-store .lSPrev").addClass("hide");

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };

      function offersDiscountPromotions(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: true,
          adaptiveHeight: false,
          slideMargin: 15,
          loop: false,
          currentPagerPosition: 'middle',
          galleryMargin: 0,
          addClass: 'controls-left-right-alt-2',
          controls: false,
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          pager: true,
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          onBeforeSlide: function (el) {
            //hide/show the left arrow
            if (el.getCurrentSlideCount() == 1) {
              $(".container-offers-promotion .lSPrev").addClass("hide");
            } else {
              $(".container-offers-promotion .lSPrev").removeClass("hide");
              $(".container-offers-promotion .lSAction").addClass("showFader");
            }

            if ((el.getCurrentSlideCount() + 1) == el.getTotalSlideCount()) {
              $(".container-offers-promotion .lSNext").addClass("hide");
            } else {
              $(".container-offers-promotion .lSNext").removeClass("hide");
            }
          },
          responsive: [{

            breakpoint: 1023,
            settings: {

              slideMargin: 15

            }

          }, {

            breakpoint: 767,
            settings: {

              slideMargin: 15

            }

          }]

        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        //hide the left arrow, at first
        $(".container-flyers-store .lSPrev").addClass("hide");

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });
      };

      function digitalKdSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: false,
          adaptiveHeight: false,
          slideMargin: 29,
          item: 3,
          loop: false,
          currentPagerPosition: 'middle',
          controls: true,
          galleryMargin: 0,
          addClass: 'leafletArrows',
          prevHtml: '<i class="icon-arrow-left-1"></i>',
          nextHtml: '<i class="icon-arrow-right-1"></i>',
          pager: true,
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          onBeforeStart: function (el) {
            $(element).find("li").css('display', 'block');
          },
          onSliderLoad: function (el) {
            $(element).find("li").css('display', 'block');
            if (element.closest('[data-component="detail-promo-store-kd"]').length > 0 || application.isMobile()) {
              if (el.find('li.active .boxKdRound').hasClass('black')) {
                if (el.closest('.leafletArrows').hasClass('pagerInside')) {
                  el.closest('.leafletArrows').removeClass('whitePager').addClass('blackPager');
                }
              }
              if (el.find('li.active .boxKdRound').hasClass('white')) {
                if (el.closest('.leafletArrows').hasClass('pagerInside')) {
                  el.closest('.leafletArrows').removeClass('blackPager').addClass('whitePager');
                }
              }

              if (el.find('li.active .boxKdRound').hasClass('openPopupKD')) {
                if (el.closest('.leafletArrows').hasClass('pagerInside')) {
                  el.closest('.leafletArrows').removeClass('blackPager').addClass('whitePager');
                }
              }
            }
          },
          onBeforeSlide: function (el) {
            //hide/show the left arrow
            if (el.getCurrentSlideCount() == 1) {
              $("[data-component='digitalShowOffersShopComponent'] .lSPrev").addClass("hide");
            } else {
              $("[data-component='digitalShowOffersShopComponent'] .lSPrev").removeClass("hide");
              $("[data-component='digitalShowOffersShopComponent'] .lSAction").addClass("showFader");
            }

            if ((el.getCurrentSlideCount() + 1) == el.getTotalSlideCount()) {
              $("[data-component='digitalShowOffersShopComponent'] .lSNext").addClass("hide");
            } else {
              $("[data-component='digitalShowOffersShopComponent'] .lSNext").removeClass("hide");
            }
          },
          onAfterSlide: function (el) {

            //show/hide next arrow
            if (application.isDesktop() || application.isDesktopXL()) {
              var sliderW = $('[data-type-component="digitalLeafletKdSlider"]');
              if (sliderW.length > 0) {
                var offset = sliderW.offset();
                var totalSlider = sliderW.width();

                var sliderWrapperW = $('[data-component="digitalShowOffersShopComponent"] [data-component="sliderContentComponent"]');
                var offset2 = sliderWrapperW.offset();
                var totalSliderWrapper = sliderWrapperW.width();

                var lastSlide = (totalSlider - totalSliderWrapper - offset2.left + offset.left);

                if (lastSlide < 29) {
                  $('[data-component="digitalShowOffersShopComponent"] [data-component="sliderContentComponent"] .lSNext').addClass("hide");
                } else {
                  $('[data-component="digitalShowOffersShopComponent"] [data-component="sliderContentComponent"] .lSNext').removeClass("hide");
                }
              }
            }

            if (element.closest('[data-component="detail-promo-store-kd"]').length > 0 || application.isMobile()) {
              if (el.find('li.active .boxKdRound').hasClass('black')) {
                if (el.closest('.leafletArrows').hasClass('pagerInside')) {
                  el.closest('.leafletArrows').removeClass('whitePager').addClass('blackPager');
                }
              } else {
                if (el.closest('.leafletArrows').hasClass('pagerInside')) {
                  el.closest('.leafletArrows').removeClass('blackPager').addClass('whitePager');
                }
              }
            }
          }
        };

        if (element.closest('[data-component="detail-promo-store-kd"]').length > 0 || application.isMobile() || application.isTablet()) {
          options.slideMargin = 15;
          options.controls = false;
          options.item = 1;
          options.addClass = 'leafletArrows pagerInside';
          if (application.isTablet()) {
            options.controls = true;
            options.item = 2;
            options.addClass = 'leafletArrows';
          }
        }


        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        //hide the left arrow, at first
        $("[data-component='digitalShowOffersShopComponent'] .lSPrev").addClass("hide");

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };

      function coopXtuttiXtePromo(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;

        var options = {

          autoWidth: false,
          adaptiveHeight: false,
          slideMargin: 0,
          item: 1,
          loop: false,
          currentPagerPosition: 'middle',
          controls: false,
          galleryMargin: 0,
          addClass: 'leafletArrows',
          prevHtml: '',
          nextHtml: '',
          pager: true,
          enableDrag: true,
          enableTouch: true,
          freeMove: true,
          responsive: [{

            breakpoint: 1023,
            settings: {

              slideMargin: 15,
              item: 2

            }

          }, {

            breakpoint: 767,
            settings: {

              slideMargin: 0,
              item: 1

            }

          }]
        };

        if ($(element).children("li").length < 2) {

          options.useCSS = false;

        }

        startSlider(element, options);

        //hide the left arrow, at first
        $("[data-component='digitalShowOffersShopComponent'] .lSPrev").addClass("hide");

        var thisSlider = element;
        var checkPager = function () {

          checkWidth(thisSlider);

        };

        checkPager();
        $(window).resize(function () {

          setTimeout(checkPager, 300);

        });

      };

      /* --------------------- */

      /**
       * Check slider width
       *
       */
      function checkWidth(thisSlider) {

        if (!thisSlider.parents('[data-component="sliderContentComponent"]').find('.lSPager').length) return;

        var widthContainerSlider = thisSlider.parent().width();
        var widthContainerSlides = thisSlider.width();

        if (widthContainerSlides < widthContainerSlider) {

          thisSlider.parents('[data-component="sliderContentComponent"]').find('.lSPager').css('visibility', 'hidden').addClass('hideDots');

        } else {

          thisSlider.parents('[data-component="sliderContentComponent"]').find('.lSPager').css('visibility', 'visible').removeClass('hideDots');

        }

      };

      /**
       * Hide arrows
       *
       */
      this.hideArrow = function (thisSlider) {

        var singleSlide = thisSlider.find('li.lslide');

        if (singleSlide.length <= 1) return;

        $.each(singleSlide, function (i) {

          if ($(this).hasClass('active')) {

            // if first slide has class active, hide left arrow
            if (i == 0) {

              $(this).parents('[data-component="sliderContentComponent"]').find('.lSPrev').css('display', 'none');
              $(this).parents('[data-component="sliderContentComponent"]').find('.lSNext').css('display', 'block');

            } else if (i == singleSlide.length - 1) { //else if last slide has class active, hide right arrow

              $(this).parents('[data-component="sliderContentComponent"]').find('.lSPrev').css('display', 'block');
              $(this).parents('[data-component="sliderContentComponent"]').find('.lSNext').css('display', 'none');

            } else { // else no slide has class active, show arrows

              $(this).parents('[data-component="sliderContentComponent"]').find('.lSPrev, .lSNext').css('display', 'block');

            }

          }

        });

      };

      /**
       * Start slider
       *
       */
      function startSlider(element, options) {
        // add option to restore visibility of the css hidden elements (li), after the slider has finished loading
        if (options != undefined && options.onSliderLoad !== undefined) {
          //
        } else {
          options.onSliderLoad = function (el) { $(element).find("li").show(); };
        }

        var type = element.attr('data-type-component');
        var slider = $(element).lightSlider(options);

        if (sliderContent.sliders == undefined) {
          sliderContent.sliders = [];
        }

        sliderContent.sliders.push({
          name: type,
          slider: slider,
          elHTML: element
        });

        // fix gallery lazy loading problem -> resize browser window when it's in viewport
        if ($('[data-type-component="gallery"]').length) {

          var galleries = $('[data-type-component="gallery"]');

          $(window).on('scroll', function () {

            galleries.each(function () {

              var $this = $(this);
              var isInViewport = application.isInViewport($this);
              var resizeAlreadyExecuted = $this.hasClass('resized');

              if (isInViewport && !resizeAlreadyExecuted) {

                // prevent to run resize multiple time for the same gallery
                $this.addClass('resized');

                // resize viewport
                setTimeout(function () {
                  $(window).resize();
                }, 500);

              }

            });

          });

        }


        $(window).on('scroll', function () {



          // resize viewport
          setTimeout(function () {
            $(window).resize();
          }, 500);


        });



        // sospendo sviluppo
        /* if( options.controls ) {
 
            //hide arrows at load page
            sliderContent.hideArrow( element );
 
            //hide arrows at click on arrows
            $( element ).parents( '.lSSlideOuter' ).find( '.lSPrev, .lSNext' ).click( function() {
 
                sliderContent.hideArrow( element );
 
            } );
 
        }
 
        if( options.pager ) {
 
 
 
        } */

      };


      function interestSlider(element) {

        if ($(element).parents(".sliderExploded").length > 0) return true;
        if (application.isMobile() || application.isTablet()) {
          var options = {

            item: 4,
            autoWidth: true,
            slideMargin: 0,
            loop: false,
            pager: true,
            addClass: 'wide-controls',
            controls: true,
            prevHtml: '<i class="icon-arrow-left-1"></i>',
            nextHtml: '<i class="icon-arrow-right-1"></i>',
            enableDrag: true,
            enableTouch: true,
            freeMove: true,
            responsive: [{

              breakpoint: 1023,
              settings: {

                item: 2,

              }

            }, {

              breakpoint: 990,
              settings: {

                item: 2,
                enableDrag: true

              }

            }, {

              breakpoint: 767,
              settings: {

                item: 1,
                enableDrag: true

              }

            }]

          };
        } else {
          return true;
        }


        startSlider(element, options);

      };



      /**
       * Refresh slider
       *
       */
      this.refreshSlider = function (element, options) {


        // add option to restore visibility of the css hidden elements (li), after the slider has finished loading
        if (options.onSliderLoad !== undefined) {
          //
        } else {
          options.onSliderLoad = function (el) { $(element).find("li").show(); };
        }

        var slider = $(element).lightSlider(options);
        slider.refresh();

      };

      /**
       * destroy slider
       *
       */
      this.destroySlider = function (element) {

        var type = element.attr('data-type-component');

        if (sliderContent.sliders) {

          for (var i = 0; i < sliderContent.sliders.length; i++) {

            if (sliderContent.sliders[i].name === type && element.is(sliderContent.sliders[i].elHTML)) {

              //obj, prop, func
              sliderContent.sliders[i].slider.destroy();
              sliderContent.sliders.splice(i, 1);

              i--;

            }

          }

        }

      };


    });
  };

  /**
   * Setup dotdotdot (truncete string)
   *
   */
  this.setupDot = function() {

    function refreshTruncate() {

        clearTimeout(window.resizedFinished);

        window.resizedFinished = setTimeout(function() {

            $(".slider-text-third-line .truncate,.subheader-content .locations,.donateInStore .locations, .slider-text-second-line.truncate, .slider-text-second-line .truncate").trigger("update");
            $(".flyer-name .truncate,.donation-summary-sticky .donation-summary-text").trigger("update");

        }, 400);

    }

    // setup rows limit on specific texts with dotdotdot
    if (!application.appStart) {

      $(".slider-text-third-line .truncate,.slider-text-second-line.truncate, .slider-text-second-line .truncate").dotdotdot({
        watch: "window",
        height: 50
      });

      $(".flyer-name .truncate").dotdotdot({
        wrap: 'letter',
        watch: 'window',
        height: 30
      });

      $(".subheader-content .locations,.donateInStore .locations").dotdotdot({
        watch: 'window',
        height: 65
      });


      $(".donation-summary-sticky .donation-summary-text").dotdotdot({
        watch: 'window',
        height: 1
      });

    }

    // update truncating on resize
    $(window).resize(function() {
      refreshTruncate();
    });

    if ($("*[data-component=sliderContentComponent]").length > 0) {
      refreshTruncate();
    }

  };


  this.setupOtpControl = function() {	
    if($('[data-component="otpControlPage"]').length > 0 && !prestitoObj.editMode) {	
      const url_otpControl = prestitoObj.context + '/2fa/?operation=send';	
      // on click [data-otp-send]
      $(document).on('click', '[data-otp-send]', function(e) {
        $.ajax({	
            'method': 'GET',	
            'url': url_otpControl,	
            'target': "no-loader",	
            'success': function(res) {	
                if (res != undefined && res.otp == true && res.sendSMS == true) {	
                      // remove class hide to [data-show-after]
                      $('[data-show-after]').removeClass('hide');
                    //create the modal	
                    application.openOtpManageModal(null, function() {	
                        window.open(application.redirectLink, "_self");	
                    });	
                      // add hide to the span that include [data-otp-send]
                      $('span:has([data-otp-send])').addClass('hide');
                } else {	
                    application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);	
                    application.temp_manageotp = false;	
                }	
            },	
            'error': function(res) {	
                application.temp_manageotp = false;	
                application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);	
            }	
        });	
      });
    }	
  }

  

  this.aperturaLibretto = function() {
    application.console.log("Init component: Apertura Libretto");

    if (application.appStart) return;

    if($('[data-breadcrumb-vincolato-step]').length > 0) {

        $(window).on("resize", function() {
            const step = $('[data-breadcrumb-vincolato-step]').data('breadcrumb-vincolato-step');
            if(step != "") {
                $('.breadcrumb_progress').css('width', $('.' + step + ' .breadcrumb__step')[0].offsetLeft);
            }
        });

        $(window).trigger('resize');
    }

    //check to see if the pins match
    this.samePinCheck = function(p1, p2, btn) {
      btn = btn || "";
      if ((p1 !== "") && (p2 !== "") && p1.length == 5 && p2.length == 5) {
          if (p1 == p2) {
            $(".pinerror").addClass("hide");
            $(".pin-container").removeClass("error");
            if (btn !== "") {
              btn.attr("disabled", false);
            }
          } else {
              $(".pinerror").removeClass("hide");
              $(".pin-container").addClass("error");
              btn.attr("disabled", true);
          }
      } else {
          btn.attr("disabled", true);
      }
    };

    // open modal on landing step 1
    if($('[data-component="librettoConfermDatiComponent"]').length > 0 && $('[data-component="librettoConfermDatiComponent"]').hasClass("step1")) {
      $('[data-component="librettoConfermDatiComponent"]').removeClass("step1");
      var _self = $('[data-component="librettoConfermDatiComponent"]');
      var title = _self.data("modal-title");
      var confirmLabel = _self.data("modal-label");
      application.confirm(
          $("#first-step-modal-template").html(),
          title,
          function() {},
          function() {},
          confirmLabel,
          ""
      );
      $('body .dialog nav .cancel').remove();
      $("body .dialog").addClass("modal800");
    }

    //step 2
    if($('[data-component="librettoAbilitaCartaComponent"]').length > 0){
      //if back check radio and input
      window.addEventListener('pageshow', function() {
        if($('[name=massimali]').length > 0){
          $("#code1").val("");
          $("#code2").val("");
          $(".aperturaLibretto__btnContainer .btn-primary").attr("disabled", true);
          if ($('[name=massimali]:checked').length) {
            $(".pin-wrapper").removeClass("hide");
            document.getElementById("scrollToPin").scrollIntoView({behavior: "smooth"});
          }else{
            $(".pin-wrapper").addClass("hide");
          }
        }
      });  
       
      // toggle show pin on .show-password-icon click
      $(".show-password-icon").on("click", function () {
        $(this).toggleClass("active");

        var container = $(this).parents(".input-container");
        var passwordInput = $("input", container);

        if (passwordInput.attr("type") === "password") {

            passwordInput.attr("type", "text");

        } else {

            passwordInput.attr("type", "password");

        }
      });

       //on radio select show pin section
      $("[name=massimali]").change(function(){
        if ($('[name=massimali]:checked').length) {
          $(".pin-wrapper").removeClass("hide");
          document.getElementById("scrollToPin").scrollIntoView({behavior: "smooth"});
        }else{
            $(".pin-wrapper").addClass("hide");
            $(".aperturaLibretto__btnContainer .btn-primary").attr("disabled", true);
        }
      });

      $(document).on("input", "#code1,#code2", function(e) {
        $(this).val($(this).val().replace(/[^0-9]/g, '')); //allow only numbers
        var p1 = $("#code1").val();
        var p2 = $("#code2").val();
        var thisOK = $(".aperturaLibretto__btnContainer .btn-primary");
        application.samePinCheck(p1, p2, thisOK);
      });

      //on next step
      $("[data-link-next]").on('click', function() {
        const massimale = $("[name=massimali]:checked").attr('value');
        const pin = $("#code1").val();
        const link = $(this).data('link-next');
        if($('[name=massimali]:checked').length) {
          $.ajax({
            'url': prestitoObj.context + `/authenticationServlet/?operation=encryptData&data=`+ pin,
            'method': 'POST',
            'dataType':'text',
            'success': function(code) {
              window.open(link + "?id=" + massimale +"&code=" + code, "_self");
            },
            'error': function() {
              application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);
            }
          });
        }else{
          window.open(link, "_self");
        }
      });
    }

    //step 3 
    if($('[data-component="librettoStepFirmaComponent"]').length > 0){
      
      // get the attribute data-stream from the .libretto-pdf-container
      var stream = $('.libretto-pdf-container').data('stream');
      var radioButtons = $("*[name=step3-radio]");
      //on change by radioButtons able the button if the radio is checked and there is a stream
      /*radioButtons.change(function(){
        //if ($("[name=step3-radio]:checked").length && stream) {
        if ($("[name=step3-radio]:checked").length && stream) {
          if($("[name=step3-radio]:checked").val() == "S"){
            $("[data-conferma-otp-contratto]").data("checkbox","S");
            $(".crUpdate").addClass("nascosto");

          }else if($("[name=step3-radio]:checked").val() == "N"){
            $("[data-conferma-otp-contratto]").data("checkbox","N");
            $(".crUpdate").removeClass("nascosto");
          }
          $("[data-conferma-otp-contratto]").attr("disabled", false);

        }else{
          $("[data-conferma-otp-contratto]").attr("disabled", true);
        }
      });*/

      //load pdf
      if($('.containerPdf').length > 0){
        const download_pdf = $('.containerPdf').data('pdf');
        if(download_pdf) {
            application.generatePDF(download_pdf);
        }
        $(document).on('click', '[data-print-pdf]', function() {
            printJS({printable: download_pdf, type: 'pdf', base64: true});
        });
        $(document).on('click', '[data-zoom-in-pdf]', function() {
            zoomPdf += 0.15;
            application.generatePDF(download_pdf);
        });
        $(document).on('click', '[data-zoom-out-pdf]', function() {
            if(zoomPdf >= 1.65) { //se zoom è >= 1.25 sottraggo, altrimenti imposto il minimo zoom possibile, 1
                zoomPdf -= 0.15;
                application.generatePDF(download_pdf);
            } else {
                zoomPdf = 1.5; 
            }
        });
      }

      //FIRMA CONTRATTO o FIRMA CLAUSOLE
      var isNextStep = null;
      $(document).on('click', '[data-conferma-otp-contratto]', function(e) {
        e.preventDefault();
        isNextStep = $(this).data("nextstep");
        application.showOtpManageModal(null, function() {
          if(isNextStep != null){
            //to next step
            application.confirmApeturaLib();
          }else{
            //show clausole
            $('[data-component="librettoStepFirmaComponent"]').removeClass("stepContratto").addClass("stepClausole");
            $("[data-conferma-otp]").attr("disabled", true); 
          }
        });
      });

      this.confirmApeturaLib = function() {
        let idRequest = $("[data-submit-apertura-lib]").data("idrequest");
        let estrattoConto = $("[data-submit-apertura-lib]").data("checkbox");
        let emailId = $("[data-submit-apertura-lib]").data("emailid");

        var titleError = $("[data-notify-messages]").data('error-title') || "";
        var textErrorGeneric = $("[data-notify-messages]").data('error-text') || "";
        var erroreMessage = `<h3><strong>${titleError}</strong></h3><p>${textErrorGeneric}</p>`;
        
        $.ajax({
          'method': 'POST',
          'url': prestitoObj.context + `/socialLoanServlet/?operation=confirmApertura&emailId=${emailId}&richiesta=${idRequest}&ec=${estrattoConto}`,
          'success': function(json) {
              if(json && json.booklet && json.booklet != ""){
                  const link = $("[data-submit-apertura-lib]").data("link") + '?nLibretto=' + json.booklet ;
                  window.open(link, "_self");
              }else{
                application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
              }
          },
          'error': function() {
            application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
          }
        });
      }
    } 

    //step4
    if($('[data-component="librettoStepCodiceIdComponent"]').length > 0){
      // manage show / hide password
      $(".show-password-icon").on("click", function (event) {

        $(this).toggleClass("active");
        
        if($(this).hasClass("material-symbols-outlined") && $(this).hasClass("active")){
            $(this).html("visibility_off"); 
        }else if($(this).hasClass("material-symbols-outlined")){
            $(this).html("visibility"); 
        }

        var container = $(this).parents(".input-container");
        var passwordInput = $("input", container);

        if (passwordInput.attr("type") === "password") {
          passwordInput.attr("type", "text");
        } else {
          passwordInput.attr("type", "password");
        }

      });

      $(document).on('input', '#code1,#code2', function() {
        $(this).val($(this).val().replace(/[^0-9]/g, '')); //allow only numbers
        
        var button = $("[data-conferma-otp]");
        if($('#code1').val() == $('#code2').val() && $('#code1').val().length == 5) {
          button.attr("disabled", false);

          $('.required-label').addClass("hide");
          $(".input-container").removeClass("error");
        } else {
          button.attr("disabled", true);

          //show error
          if($('#code1').val().length == 5 && $('#code2').val().length == 5){
            $('.required-label').removeClass("hide");
            $(".input-container").addClass("error");
          }
        }
      });

      $(document).on('click', '[data-conferma-otp]', function(e) {
        e.preventDefault();

        var ean_card = $(this).data("eancard");

        var titleError = $("[data-notify-messages]").data('error-title') || "Errore";
        var textErrorGeneric = $("[data-notify-messages]").data('error-text') || "Si è verificato un errore";
        var erroreMessage = `<h3><strong>${titleError}</strong></h3><p>${textErrorGeneric}</p>`;

        //regolamento pin
        $.ajax({
          'url': prestitoObj.context + '/socialLoanServlet/?operation=richRegolamentoPin&eanCard=' + ean_card + '&tipoOperazione=I',
          'method': 'POST',
          'target': 'body',
          'success': function(res) {
              if(res && res.return_code.return_param == "00"){
                var json = res.regolamento_pin;
                var userpin = $('#code1').val();
                application.modalRulesUserPin(json.stream, userpin, json.nro_richiesta_pin);
              }else{
                application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
              }
          },
          'error': function (json) {
            application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
          }
        });
      });

      //open a modal with rules change pin
      this.modalRulesUserPin = function(download_pdf, userpin, nro_richiesta_pin) {
        userpin = userpin || "";
        nro_richiesta_pin = nro_richiesta_pin || "";

        var target = $("[modal-rules-userpin]");
        
        var modalTitle = target.data('title') || "";

        var titleError = $("[data-notify-messages]").data('error-title') || "";
        var textErrorGeneric = $("[data-notify-messages]").data('error-text') || "";
        var erroreMessage = `<h3><strong>${titleError}</strong></h3><p>${textErrorGeneric}</p>`;

        if(!download_pdf){ 
          application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
          return;
        }
        
        application.confirm(
          target.html(),
          modalTitle,
          function () {     
            application.showOtpManageModal(null, function() {
              $.ajax({
                'url': prestitoObj.context + '/socialLoanServlet/?operation=insertPinDemaLibretto&pin=' + userpin + '&numeroRichiestaPin=' + nro_richiesta_pin ,
                'method': 'POST',
                'target': 'body',
                'success': function(json) {
                    if(json){
                      window.open(target.data('link'), "_self");
                    }else{
                      application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
                    }
                },
                'error': function (json) {
                    application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
                }
              });
            });
          },
          function () {},
          '',
          ''
        );

        $('.alertify').addClass('overflow-scroll-modal');
        $('.alertify .dialog').addClass('modal_800');
        $('.alertify .dialog nav:not(".modalBottom")').remove();

        $('.alertify .modalContractDematLib [data-pdf-frame]').attr("id", "pdfIframe");
        
        if (download_pdf){
          application.generatePDF(download_pdf);

          $(document).on('click', '[data-print-pdf]', function() {
            printJS({printable: download_pdf, type: 'pdf', base64: true});
          });
          $(document).on('click', '[data-zoom-in-pdf]', function() {
              zoomPdf += 0.15;
              application.generatePDF(download_pdf);
          });
          $(document).on('click', '[data-zoom-out-pdf]', function() {
              if(zoomPdf >= 1.65) { //se zoom è >= 1.25 sottraggo, altrimenti imposto il minimo zoom possibile, 1
                  zoomPdf -= 0.15;
                  application.generatePDF(download_pdf);
              } else {
                  zoomPdf = 1.5; 
              }
          });
        } 
      }
    } 
    /**/

    //step5
    if($('[data-component="librettoEsitoBoxComponent"]').length > 0){
      //modal more info
      if($('[data-m-moreInfo]').length > 0) {
        $(document).on('click', '[data-m-moreInfo]', function() {     
          const link = $(this).data('m-confirm-link') + "?scrollTo=iban-top-container"
          application.confirm(
            `<div class='container-generic-structure'>
              <div class='textContainer'>
                ${$(this).data('m-text')}
              </div>
            </div>`,
            $(this).data('m-title'),
            function() {
              window.open(link, "_self");
            },
            function() {},
            $(this).data('m-confirm'),
            ""
          );
          $('body .dialog nav .cancel').remove();
        });
      }

      $("[go-to-booklet]").on('click', function(e) {
        const link = $(this).data('link');
        const booklet = $(this).data('booklet');
        sessionStorage.setItem('bookeltTab', booklet);
        sessionStorage.setItem('newBooklet', true);
        window.open(link, "_self");
      });
    }
  }

  this.dematLibretto = function() {
    application.console.log("Init component: Dematerializzazione Libretto");

    if (application.appStart) return;

    if($('[data-breadcrumb-vincolato-step]').length > 0) {

        $(window).on("resize", function() {
            const step = $('[data-breadcrumb-vincolato-step]').data('breadcrumb-vincolato-step');
            if(step != "") {
                $('.breadcrumb_progress').css('width', $('.' + step + ' .breadcrumb__step')[0].offsetLeft);
            }
        });

        $(window).trigger('resize');
    }

    // open modal select booklet step 1
    if($('[data-component="dematLibrettoScegliLibrettoComponent"]').length > 0) {
      $("[open-modal-selectbooklet]").on("click", function(){
        var modal = $("[data-modal-selectbooklet]");
        var title = modal.data("title");
        var confirmLabel = modal.data("confirm");
        var cancelLabel = modal.data("annulla");
        application.confirm(
          modal.html(),
          title,
          function() {
            var selected = $('input[name="booklet-radio"]:checked').val()

            window.open('?nl1=' + selected, "_self");
          },
          function() {},
          confirmLabel,
          cancelLabel
        );

        $("body .dialog").addClass("modal800");
        $('.alertify').addClass('selectbooklet_modal overflow-scroll-modal');
        $('.selectbooklet_modal .ok').attr("disabled", true);

        $('.selectbooklet_modal .radio-box [name="booklet-radio"]').each(function(){
          $(this).attr("id", $(this).data("value"));
          $(this).val($(this).data("value"));
        });

        //click radio on click box
        $(".selectbooklet_modal .radio-box").off("click");
        $(".selectbooklet_modal .radio-box").on("click", function(e){
          e.stopPropagation();
          $("[name='booklet-radio']:checked").prop("checked",false);
          $(this).find("[name='booklet-radio']").prop("checked",true);
          $("[name='booklet-radio']:checked").trigger("change");
        });

        $('input[name="booklet-radio"]').on("change", function(){
          $('.selectbooklet_modal .ok').attr("disabled", false);
        })
      });

      $("[submit-demat-lib]").on('click', function(e) {
        var link = $(this).data("link") + $(this).data("params")
        window.open(link, "_self");
      });
    }

    //step 2 
    if($('[data-component="dematLibrettoFirmaContrattoComponent"]').length > 0){
        
      $(document).on('click', '[data-conferma-otp]', function(e) {
        e.preventDefault();
        application.showOtpManageModal(null, function() {
            const link = $('[data-conferma-otp]').data('link');
            window.open(link, "_self");
        });
      });

      //load pdf
      if($('.containerPdf').length > 0){
        const download_pdf = $('.containerPdf').data('pdf');
        if(download_pdf) {
            application.generatePDF(download_pdf);
        }
        $(document).on('click', '[data-print-pdf]', function() {
            printJS({printable: download_pdf, type: 'pdf', base64: true});
        });
        $(document).on('click', '[data-zoom-in-pdf]', function() {
            zoomPdf += 0.15;
            application.generatePDF(download_pdf);
        });
        $(document).on('click', '[data-zoom-out-pdf]', function() {
            if(zoomPdf >= 1.65) { //se zoom è >= 1.25 sottraggo, altrimenti imposto il minimo zoom possibile, 1
                zoomPdf -= 0.15;
                application.generatePDF(download_pdf);
            } else {
                zoomPdf = 1.5; 
            }
        });
      }
    }

    //step 3 
    if($('[data-component="dematLibrettoFirmaClausoleComponent"]').length > 0){
      $(document).on('click', '[data-conferma-otp]', function(e) {
          e.preventDefault();

          const link = $('[data-conferma-otp]').data('link');
          var numRichiestaDema = $('[data-conferma-otp]').data("nr");
          var numLibretto = $('[data-conferma-otp]').data("nl1");
          var numLibretto2 = $('[data-conferma-otp]').data("nl2");
          var titleError = $("[data-notify-messages]").data('error-title') || "";
          var textErrorGeneric = $("[data-notify-messages]").data('error-text') || "";
          var erroreMessage = `<h3><strong>${titleError}</strong></h3><p>${textErrorGeneric}</p>`;

          application.showOtpManageModal(null, function() {        
            $.ajax({
              'url': prestitoObj.context + '/socialLoanServlet/?operation=confirmDemaLibretto&numeroLibretto=' + numLibretto + '&numeroLibretto2=' + numLibretto2 + '&numeroRichiestaDema=' + numRichiestaDema,
              'method': 'POST',
              'target': 'body',
              'success': function(res) {
                  if(res){
                    window.open(link, "_self");
                  }else{
                    application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
                  }
              },
              'error': function (json) {
                application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
              }
            });
          });
      });
    } 

    //step4
    if($('[data-component="dematLibrettoCodiceIdComponent"]').length > 0){
      // manage show / hide password
      $(".show-password-icon").on("click", function (event) {

        $(this).toggleClass("active");
        
        if($(this).hasClass("material-symbols-outlined") && $(this).hasClass("active")){
            $(this).html("visibility_off"); 
        }else if($(this).hasClass("material-symbols-outlined")){
            $(this).html("visibility"); 
        }

        var container = $(this).parents(".input-container");
        var passwordInput = $("input", container);

        if (passwordInput.attr("type") === "password") {
          passwordInput.attr("type", "text");
        } else {
          passwordInput.attr("type", "password");
        }

      });

      $(document).on('input', '#code1,#code2', function() {
        $(this).val($(this).val().replace(/[^0-9]/g, '')); //allow only numbers
        
        var button = $("[data-conferma-otp]");
        if($('#code1').val() == $('#code2').val() && $('#code1').val().length == 5) {
          button.attr("disabled", false);

          $('.required-label').addClass("hide");
          $(".input-container").removeClass("error");
        } else {
          button.attr("disabled", true);

          //show error
          if($('#code1').val().length == 5 && $('#code2').val().length == 5){
            $('.required-label').removeClass("hide");
            $(".input-container").addClass("error");
          }
        }
      });

      $(document).on('click', '[data-conferma-otp]', function(e) {
        e.preventDefault();

        var ean_card = $(this).data("eancard");

        var titleError = $("[data-notify-messages]").data('error-title') || "";
        var textErrorGeneric = $("[data-notify-messages]").data('error-text') || "";
        var erroreMessage = `<h3><strong>${titleError}</strong></h3><p>${textErrorGeneric}</p>`;

        //regolamento pin
        $.ajax({
          'url': prestitoObj.context + '/socialLoanServlet/?operation=richRegolamentoPin&eanCard=' + ean_card + '&tipoOperazione=I',
          'method': 'POST',
          'target': 'body',
          'success': function(res) {
              if(res && res.return_code.return_param == "00" ){
                var json = res.regolamento_pin;
                var userpin = $('#code1').val();
                application.modalRulesUserPin(json.stream, userpin, json.nro_richiesta_pin);
              }else{
                application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
              }
          },
          'error': function (json) {
            application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
          }
        });
      });

      //open a modal with rules change pin
      this.modalRulesUserPin = function(download_pdf, userpin, nro_richiesta_pin) {
        userpin = userpin || "";
        nro_richiesta_pin = nro_richiesta_pin || "";

        var target = $("[modal-rules-userpin]");
        
        var modalTitle = target.data('title') || "";
        var link = target.data("link");
        var numLibretto2 = target.data("nl2") || "";

        var titleError = $("[data-notify-messages]").data('error-title') || "";
        var textErrorGeneric = $("[data-notify-messages]").data('error-text') || "";
        var erroreMessage = `<h3><strong>${titleError}</strong></h3><p>${textErrorGeneric}</p>`;

        if(!download_pdf){ 
          application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
          return;
        }
        
        application.confirm(
          target.html(),
          modalTitle,
          function () {     
            application.showOtpManageModal(null, function() {
              $.ajax({
                'url': prestitoObj.context + '/socialLoanServlet/?operation=insertPinDemaLibretto&pin=' + userpin + '&numeroRichiestaPin=' + nro_richiesta_pin ,
                'method': 'POST',
                'target': 'body',
                'success': function(json) {
                    if(json){
                      if(numLibretto2 && numLibretto2 == ""){
                        sessionStorage.setItem('successDemat', '1');
                      }else{
                        sessionStorage.setItem('successDemat', '2');
                      }
                      
                      window.open(link, "_self");
                    }else{
                      application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
                    }
                },
                'error': function (json) {
                    application.notify(erroreMessage, "error",undefined,undefined,undefined,"Chiudi");
                }
              });
            });
          },
          function () {},
          '',
          ''
        );

        $('.alertify').addClass('overflow-scroll-modal');
        $('.alertify .dialog').addClass('modal_800');
        $('.alertify .dialog nav:not(".modalBottom")').remove();

        $('.alertify .modalContractDematLib [data-pdf-frame]').attr("id", "pdfIframe");
        
        if (download_pdf){
          application.generatePDF(download_pdf);

          $(document).on('click', '[data-print-pdf]', function() {
            printJS({printable: download_pdf, type: 'pdf', base64: true});
          });
          $(document).on('click', '[data-zoom-in-pdf]', function() {
              zoomPdf += 0.15;
              application.generatePDF(download_pdf);
          });
          $(document).on('click', '[data-zoom-out-pdf]', function() {
              if(zoomPdf >= 1.65) { //se zoom è >= 1.25 sottraggo, altrimenti imposto il minimo zoom possibile, 1
                  zoomPdf -= 0.15;
                  application.generatePDF(download_pdf);
              } else {
                  zoomPdf = 1.5; 
              }
          });
        } 
      }

      
      $("[submit-demat-lib]").on('click', function(e) {
        var link = $(this).data("link");
        var numLibretto2 = $(this).data("nl2");

        if(numLibretto2 && numLibretto2 == ""){
          sessionStorage.setItem('successDemat', '1');
        }else{
          sessionStorage.setItem('successDemat', '2');
        }
        
        window.open(link, "_self");
      });
    } 
    
  };

  //modale abbandono flussi
  this.modaleAbbandonoFlussi = function() {
    if($('[data-m-abbandono-flussi]').length > 0) {
      $(document).on('click', '[data-m-abbandono-flussi]', function() {  
          console.log('initAbbandono');
          var _this =  $('[data-component="modaleAbbandonoFlussi"]')
          const link = _this.data('m-abbandono-link-abbandona')
          application.confirm(
          `<div class='container-generic-structure'>
              <div class='textContainer'>
              ${_this.data('m-abbandono-testo')}
              </div>
          </div>`,
          _this.data('m-abbandono-title'),
          function() {
              if($('.aperturaLibretto__steps').length > 0){
                $.ajax({
                    'url': prestitoObj.context + `/socialLoanServlet/?operation=insertAbbandonoApertura`,
                    'method': 'POST',
                    'target': 'body',
                    'success': function() {
                    window.open(link, "_self");
                    },
                    'error': function() {
                    application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);
                    }
                });
              }else if($('.dematLibretto__steps').length > 0){
                window.open(link, "_self");
              }else{
                window.open(link, "_self");
              }
          },
          function() {},
          _this.data('m-abbandono-label-abbandona'),
          _this.data('m-abbandono-label-rimani')
          );
          $("body .dialog").addClass("modal800");
      });
    }
  }

  this.convertDataURIToBinary = function(dataURI) {
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for(var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  };

  
  /**
 * Generate PDF with pdfjs
 */
  this.generatePDF = function(url) {

    url = url||"";
    var pdfjsLib = window['pdfjs-dist/build/pdf'];    
    pdfjsLib.GlobalWorkerOptions.workerSrc = prestitoObj.context + prestitoObj.pathLIBS + '/jspdf/pdf.worker.js';

    if(url.indexOf('data:application/pdf') == -1) {
      url = "data:application/pdf;base64,"+url;
    }

    var pdfAsArray = application.convertDataURIToBinary(url);

    var loadingTask = pdfjsLib.getDocument(pdfAsArray);
    $('#pdfIframe').html();

    loadingTask.promise.then(function(pdf) {

      var __TOTAL_PAGES = pdf.numPages; 
      // Fetch the first page
      var pageNumber = 1;
      for(var i = 1; i <= __TOTAL_PAGES; i += 1) {
          if(i == 1) {
              let id ='the-canvas'+i;
              $('#pdfIframe canvas').remove();
              $('#pdfIframe').append("<canvas id='"+id+"'></canvas>");	
              
              zoomPdf == 1.5 ?  $('#pdfIframe canvas').css('width', '100%') : $('#pdfIframe canvas').css('width', 'auto');		
              
              let canvas = document.getElementById(id);
              renderPage(canvas, pdf, pageNumber++, function pageRenderingComplete() {
                  if (pageNumber > pdf.numPages) {
                      return; 
                  }
              });				
          }
      }   
                    
    });    
          
    function renderPage(canvas, pdf, pageNumber, callback, scale) {
      pdf.getPage(pageNumber).then(function(page) {
          var scale = zoomPdf;
          var viewport = page.getViewport({scale: scale});            
          var pageDisplayWidth = viewport.width;
          var pageDisplayHeight = viewport.height;
          var context = canvas.getContext('2d');
          canvas.width = pageDisplayWidth;
          canvas.height = pageDisplayHeight;
          // Render PDF page into canvas context
          var renderContext = {
              canvasContext: context,
              viewport: viewport
          };
          page.render(renderContext).promise.then(callback);
      });
    } 

  };



  this.manageOtpLength = function() {
    function maxLengthCheck(val, maxLength) {
        if (val.length > maxLength) {
            return val.slice(0, maxLength);
        } else {
            return val;
        }
    }

    $(document).on('input', 'input[name="otp"]', function() {
        this.value = this.value.replace(/[^0-9]/g, '');

        $(this).val(maxLengthCheck($(this).val(), parseInt($(this).attr('maxlength'))));

        if ($(this).val().length >= $(this).attr('maxlength')) {
            $(this).parents('[data-component="formContactsComponent"]').next('.btn-primary').removeAttr('disabled');
            $(this).parents('[data-component="formContactsComponent"]').next('.container_btn_flex').children('.btn-primary').removeAttr('disabled');
        } else {
            $(this).parents('[data-component="formContactsComponent"]').next('.btn-primary').attr('disabled', 'disabled');
            $(this).parents('[data-component="formContactsComponent"]').next('.container_btn_flex').children('.btn-primary').attr('disabled', 'disabled');
        }
    });
  };

    // successo inserimento otp
  this.otpSuccessPopupComponent = function(callback) {
    //if ($('.alertify .msgModal').length > 0) return;
    application.confirm($('[data-component="otpSuccessPopupComponent"]').html(), $('[data-component="otpSuccessPopupComponent"]').attr('data-title'), undefined, undefined, undefined, undefined);
    $('.alertify .dialog').addClass('modalOtp');
    $('.alertify .icon-close').hide();
    setTimeout(function() {
        $("body .alertify").remove();
        callback();
    }, 2000);
  };

  this.otpSuccessComponent = function(callback) {
      
    $('[data-component="success"]').removeClass('hide');
    $('[data-component="otpControlPage"]').addClass('hide');
    setTimeout(function() {
        callback();
    }, 2000);

  }

  //call the servlet that decides to show the modal or not
  this.showOtpManageModal = function(elem, callback) {
    
    $('[data-component="' + elem + '"] form').submit(function(e) {
        e.preventDefault();
    });

    var url_otp = prestitoObj.context + '/2fa/?operation=send';
    $.ajax({
        'method': 'GET',
        'url': url_otp,
        'target': "no-loader",
        'success': function(res) {
            if (res != undefined && res.otp == true && res.sendSMS == true) {
                //create the modal
                application.openOtpManageModal(elem, callback);
            } else {
                application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);
                application.temp_manageotp = false;
            }
        },
        'error': function(res) {
            application.temp_manageotp = false;
            application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);
        }

    });
  };

  this.openOtpManageModal = function(element, callback) {
    //create the modal
    //if ($('.alertify .msgModal').length > 0) return;
    
    application.manageOtpLength();

    $(document).on('click', '.alertify .icon-close', function() {
        application.temp_manageotp = false;
    });

    //on [ENTER] confirm
    $(".otpDashboardPopup").keyup(function(event) {
      if (event.keyCode === 13) {
       $(".otpDashboardPopup .btn-primary[data-confirm-otp]").trigger("click");
      }
    });
      

    $(document).on('click', '.otpDashboardPopup .btn-primary[data-confirm-otp]', function() {

        var self = $(this);

        if (application.temp_btnmanagerclick) return;
        application.temp_btnmanagerclick = true;

        if (self.attr('data-loading-btn') && self.attr('data-loading-btn').length > 0) {
            self.html(self.attr('data-loading-btn') + '<em></em>');
        } else if (self.attr('data-label-btn') && self.attr('data-label-btn').length > 0) {
            self.html(self.attr('data-label-btn') + '<em></em>');
        }

        var val_otp = $(".msgModal input[name='otp']").val() || "";
        // 'https://5f2014dbfba6d400169d493f.mockapi.io/api/otp/activate/verified/1', //

        if($('[data-component="otpControlPage"]').length > 0) {
            val_otp = $("input[name='otp']:visible").val() || "";
        }

        var data = { "pin": val_otp };
        if ($('[data-otp-servlet]').length > 0 || $('[data-otp-regolamento]').length > 0 || $('[data-component="datiMancanti"]').length > 0) {
            data = {
                "pin": val_otp,
                "regolamentoOtp": true
            }
        }

        var urlVerify = '/2fa/?operation=verify';
        if($('[data-component="otpControlPage"]').length > 0) {
            urlVerify = '/2fa/?operation=verifyPrestito';
        }

        $.ajax({
            'method': 'GET',
            'url': prestitoObj.context + urlVerify,
            'data': data,
            'target': $("em", self),
            "loaderType": "btnPrimary",
            'success': function(res) {
                setTimeout(function() {
                    application.temp_btnmanagerclick = false;
                }, 500);

                if (res.verified != undefined && res.verified) {
                    if(res.redirectPage != undefined && res.redirectPage) {
                        application.redirectLink = res.redirectPage;
                        if ($('input.openOTPmodal').length > 0) {
                            $('input.openOTPmodal').removeClass('openOTPmodal');
                        }
                        $('.alertify .icon-close').trigger('click');
                        application.otpSuccessComponent(function() {
                            if(callback) callback();
                        });
                    } else {
                        if ($('input.openOTPmodal').length > 0) {
                            $('input.openOTPmodal').removeClass('openOTPmodal');
                        }
                        $('.alertify .icon-close').trigger('click');
                        if(element) {
                          application.otpSuccessPopupComponent(function() {
                            if (element === 'adhesionComponent') {
                                $('[data-component="' + element + '"] form').append('<input type="hidden" name="pin" value="' + val_otp + '"/>')
                            }
                                $('[data-component="' + element + '"] form').off('submit');
                                $('[data-component="' + element + '"] form').submit();
                            });
                        } else {
                          application.otpSuccessPopupComponent(function() {
                                if(callback) callback();
                            });
                        }
                    }
                } else {
                    if (res.pinError != undefined) {
                        // se expired -> aggiungere '-expired' come secondo parametro a erroCodeinPopup
                        var expired = '';
                        if (res.pinError.toLowerCase().indexOf('expired') > -1) expired = '-expired';
                        application.errorCodeinPopup(self, expired);
                    } else {
                        application.errorCodeinPopup(self);
                    }
                }
                self.html(self.attr('data-label-btn'));
            },
            'error': function(res) {
                self.html(self.attr('data-label-btn'));
                application.errorCodeinPopup(self);
                setTimeout(function() {
                    application.temp_btnmanagerclick = false;
                }, 500);
            }

        });
    });

    $(document).on('click', 'a[data-otpresend]', function() {
        if (application.temp_resend) return;
        application.temp_resend = true;

        $(this).parents('.msgModal .otpDashboardPopup').find('.required-label').hide();
        $(this).parents('.msgModal .otpDashboardPopup').find('.input-container').removeClass('error');
        $(this).parents('.msgModal .otpDashboardPopup').find('input[name="otp"]').val('');
        $(this).parents('.msgModal .otpDashboardPopup').find('input[name="otp"]').focus();
        $(this).parents('.msgModal .otpDashboardPopup').find('.btn-primary').attr('disabled', 'disabled');

        $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').removeClass('hide');
        if ($('.dialog .msgModal .otpDashboardPopup .otp-other .loading .loader-overlay').length == 0) {
            $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').append('<div class="loader-overlay"></div>');
        }
        $('.dialog .msgModal .otpDashboardPopup .otp-other .resendSms').addClass('hide');

        var data = {};
        if ($('[data-component="datiMancanti"]').length > 0) {
            data = {
                "isAnonymous": true,
                "name": $('[data-component="datiMancanti"]').data('nome-utente')
            };
        }

        $.ajax({
            //  'https://5f2014dbfba6d400169d493f.mockapi.io/api/otp/activate/resend/1'
            'method': 'GET',
            'url': prestitoObj.context + '/2fa/?operation=resend',
            "data": data,
            'success': function(res) {
                setTimeout(function() {
                    application.temp_resend = false;
                }, 500);
                $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').addClass('hide');
                $('.dialog .msgModal .otpDashboardPopup .otp-other .resendSms.step2').removeClass('hide');
                if (res == true) {

                } else {

                }
            },
            'error': function(res) {
                setTimeout(function() {
                    application.temp_resend = false;
                    $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').addClass('hide');
                    $('.dialog .msgModal .otpDashboardPopup .otp-other .resendSms.step1').removeClass('hide');
                }, 500);
            }

        });
    });

    if($('[data-component="otpControlPage"]').length == 0) {
        application.confirm($('[data-component="otpManagerPopupComponent"]').html(), $('[data-component="otpManagerPopupComponent"]').attr("data-title"), undefined, undefined, undefined, undefined);
        $('.alertify .dialog').addClass('modalOtp');
    }

    if(element == 'profilePartnerDataComponent') {
        var replaceElement = "<strong>" + $('#mobile').val() +"</strong>";
        
        if($("#mobile").length > 0 && $("#mobile").attr("data-db-value") !== $("#mobile").val() && $("#mobile").val().length > 0) {
            $('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text($('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text().replace('{numTel}', replaceElement));
            $('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text($('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text().replace('{cambioNumero}', '<strong>nuovo numero</strong>'));
            $('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').html($('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text());
        } else {
            $('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text($('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text().replace('{numTel}', replaceElement));
            $('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text($('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text().replace('{cambioNumero}', '<strong>numero</strong>'));
            $('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').html($('.dialog .msgModal .otpDashboardPopup .otp-subtitle:not(.marginTop30)').text());
            
            var iconX = $('.dialog .msgModal h4')[0].firstElementChild;
            $('.dialog .msgModal h4').text('Conferma la variazione della mail con OTP').append(iconX);
        }
        $('.dialog .msgModal .otpDashboardPopup .marginTop30').addClass('hide');
    }

  };

  $(document).on('click', 'a[data-otpresend]', function() {
    if (application.temp_resend) return;
    application.temp_resend = true;

    $(this).parents('.msgModal .otpDashboardPopup').find('.required-label').hide();
    $(this).parents('.msgModal .otpDashboardPopup').find('.input-container').removeClass('error');
    $(this).parents('.msgModal .otpDashboardPopup').find('input[name="otp"]').val('');
    $(this).parents('.msgModal .otpDashboardPopup').find('input[name="otp"]').focus();
    $(this).parents('.msgModal .otpDashboardPopup').find('.btn-primary').attr('disabled', 'disabled');

    $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').removeClass('hide');
    if ($('.dialog .msgModal .otpDashboardPopup .otp-other .loading .loader-overlay').length == 0) {
        $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').append('<div class="loader-overlay"></div>');
    }
    $('.dialog .msgModal .otpDashboardPopup .otp-other .resendSms').addClass('hide');

    var data = {};
    if ($('[data-component="datiMancanti"]').length > 0) {
        data = {
            "isAnonymous": true,
            "name": $('[data-component="datiMancanti"]').data('nome-utente')
        };
    }

    $.ajax({
        //  'https://5f2014dbfba6d400169d493f.mockapi.io/api/otp/activate/resend/1'
        'method': 'GET',
        'url': prestitoObj.context + '/2fa/?operation=resend',
        "data": data,
        'success': function(res) {
            setTimeout(function() {
                application.temp_resend = false;
            }, 500);
            $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').addClass('hide');
            $('.dialog .msgModal .otpDashboardPopup .otp-other .resendSms.step2').removeClass('hide');
            if (res == true) {

            } else {

            }
        },
        'error': function(res) {
            setTimeout(function() {
                application.temp_resend = false;
                $('.dialog .msgModal .otpDashboardPopup .otp-other .loading').addClass('hide');
                $('.dialog .msgModal .otpDashboardPopup .otp-other .resendSms.step1').removeClass('hide');
            }, 500);
        }

    });
  });



  // errori popup inserimento e codice scaduto
  this.errorCodeinPopup = function(element, expired) {
      expired = expired != undefined ? expired : '';
      element.parents('.otpDashboardPopup').find("input[name='otp']").val('');
      element.parents('.otpDashboardPopup').find('.btn-primary').attr('disabled', 'disabled');
      var error_code = element.parents('.otpDashboardPopup').find('.required-label').attr('data-error-code' + expired);
      element.parents('.otpDashboardPopup').find('.required-label div p small').text(error_code);
      element.parents('.otpDashboardPopup').find('.required-label').show();
      element.parents('.otpDashboardPopup').find('.input-container').addClass('error');
  };

  /**
   * Init ajax calls
   *
  */
  this.initAjax = function() {

    // ajax calls global setup
    $.ajaxSetup({

      type: "post",
      cache: false,
      beforeSend: function(jqXHR) {
          
        application.ajaxLoader(true, this.target, this.loaderType); // start ajax loader
        application.startAjaxTime = new Date().getTime(); // profiling

      },

      complete: function(response) {

        // profiling
        application.stopAjaxTime = new Date().getTime();
        application.callTime = application.stopAjaxTime - application.startAjaxTime;

        // log object
        var log = {
          URL: this.url,
          TYPE: this.type,
          TIME: application.callTime + "ms"
        };

        application.console.debug("AJAX CALL: " + JSON.stringify(log, null, 5));

        application.ajaxLoader(false, this.target); // close loader

      }

    });

  };

  /**
   * Init check responsive
   *
   */
  this.initResponsive = function() {

    // check app started
    if (!application.appStart) {

      /**
       * Callback change media query
      */
      const mediaQueryResponseM = function(mqMobile) {
        if (window.mqMobile.matches) { application.callbackChangeDevice(); }
      };

      const mediaQueryResponseT = function(mqTablet) {
        if (window.mqTablet.matches) { application.callbackChangeDevice(); }
      };

      const mediaQueryResponseD = function(mqDesktop) {
        if (window.mqDesktop.matches) { application.callbackChangeDevice(); }
      };

      const mediaQueryResponseX = function(mqDesktopxl) {
        if (window.mqDesktopxl.matches) { application.callbackChangeDevice(); }
      };

      // media queries
      const mobile = "only screen and (max-width: 767px)";
      const tablet = "only screen and (min-width: 768px) and (max-width: 1023px)";
      const desktop = "only screen and (min-width: 1024px) and (max-width: 1179px)";
      const desktopxl = "only screen and (min-width: 1180px)";

      // check device mobile
      window.mqMobile = window.matchMedia(mobile);
      window.mqMobile.addListener(mediaQueryResponseM);

      // check device tablet
      window.mqTablet = window.matchMedia(tablet);
      window.mqTablet.addListener(mediaQueryResponseT);

      // check desktop
      window.mqDesktop = window.matchMedia(desktop);
      window.mqDesktop.addListener(mediaQueryResponseD);

      // check desktopXL
      window.mqDesktopxl = window.matchMedia(desktopxl);
      window.mqDesktopxl.addListener(mediaQueryResponseX);

    }

    // log
    if (application.isMobile()) {application.console.debug("Check device: MOBILE"); sessionStorage.setItem("curDevice","isMobile");}
    if (application.isTablet()) {application.console.debug("Check device: TABLET"); sessionStorage.setItem("curDevice","isTablet");}
    if (application.isDesktop()) {application.console.debug("Check device: DESKTOP"); sessionStorage.setItem("curDevice","isDesktop");}
    if (application.isDesktopXL()) {application.console.debug("Check device: DESKTOP XL"); sessionStorage.setItem("curDevice","isDesktopXL");}
  };

  /**
   * Callback change media query
   *
  */
  this.callbackChangeDevice = function() {

    // reboot application
    application.skipLoadFiles = true;
    application.console.clear();
    application.boot();

    $(document).trigger( "deviceChanged" );

  };

  /**
   * Init cookies
   *
  */
  this.initCookie = function() {

    // init cookie plugin
    $.cookie({
      expires: 365,
      path: '/' 
    });

    // log cookies
    application.logCookie();

    if($('[data-component="otpControlPage"]').length == 0) {
      // set cookie for otp 
      $.cookie("otpControlRefresh", "false", { path: '/' });
    }

  };


  /* --------------------- SETUP ----------------------------------------------------------------------------- */

  /**
   * Setup application execution environment
   *
  */
  this.setupEnvironment = function() {

    // get environment from jsLayer
    application.env = prestitoObj.environment;

    // log
    application.console.debug("Execution environment: " + application.env.toUpperCase());
  };

  /**
   * Setup logger tool
   *
  */
  this.setupLogger = function() {
    // setup console
    if (application.isDevel()) {
      // log
      application.console.debug("Development mode (js/css expanded & console enabled)");
    } else {
      // check cookies debug
      if ($.cookie("forceExpandedAssets") != undefined && $.cookie("forceExpandedAssets") == "true") application.forceExpandedAssets = true;
      if ($.cookie("forceShowConsole") != undefined && $.cookie("forceShowConsole") == "true") application.forceShowConsole = true;

      if (application.forceExpandedAssets && !application.forceShowConsole) {
        application.console.debug("Production Debug mode (js/css expanded & console disabled)");
        application.showConsole = false; // disable js console
      } else if (!application.forceExpandedAssets && application.forceShowConsole) {
        application.console.debug("Production Debug mode (js/css minified & console enabled)");
      } else if (application.forceExpandedAssets && application.forceShowConsole) {  
        application.console.debug("Production Debug mode (js/css expanded & console enabled)");
      } else {
        application.console.debug("Production mode (js/css minified and console disabled)");
        application.showConsole = false; // disable js console
      }
    }
  };

  /**
   * Setup full-width components
   *
  */
  this.setupFullWidthComponents = function() {

    // fix full-width row with scrollbars
    if ($.hasScrollBar()) {
      $(".row-full").css({
          "width": "calc( 100vw - " + $.getScrollbarWidth() + "px )",
          "left": "calc( 50% - " + ($.getScrollbarWidth() / 2) + "px )",
          "margin-left": "calc( -50vw + " + $.getScrollbarWidth() + "px )"
      });
    }

  };

  /**
   * Setup automatic alert messages
   *
  */
  this.setupAlertMessages = function() {

    // check app started
    if (application.appStart) return;

    var alerts = $(".alertMessage"); // get messages

    // check messages
    if (alerts.length > 0) {

      // fetch messages list
      for (var index = 0; index < alerts.length; index++) {

        // active message
        var alert = $(alerts[index]);

        // create alert
        application.alert("<h4>" + alert.attr("data-title") + "</h4>" +
            "<div class='container-generic-structure'>" +
            "<div class='textContainer'>" + alert.html() + "</div>" +
            "</div>", alert.attr("data-btnok"));
      }

    }
  };

  this.setupSlider = function() {

    if (!application.appStart && !application.isEditMode()) {

      if ($("*[data-component=carouselVantaggi]").length > 0) {

        // log
        application.console("Detected component: STORE MAP");

        // styles to load
        styles = {
          "lightslider/css/lightslider": "lib"
        };
        application.loadStyle(styles);

        // scripts to load
        scripts = {
          "lightslider/js/lightslider" : "lib",
        };

        application.loadScript(scripts, function() {
      
          $("*[data-component=carouselVantaggi]").each(function() {
            let _self = $(this);
            $(this).find('[data-carousel-title]').each(function(index) {
              _self.find('.vantaggi__elementListContainer').append('<p data-carousel=' + index +'>' + $(this).text() +'</p>'); //aggiungo i titoli a sinistra
            });
            $(this).find('.vantaggi__elementListContainer p').eq(0).addClass('active'); //aggiungo la classe active solo al primo elemento
          });

          $('[data-component=carouselVantaggi] .carousel').lightSlider({
            item: 1,
            slideMargin: 0,
            adaptiveHeight: false,
            prevHtml: '<i class="icon-arrow-left-1"></i>',
            nextHtml: '<i class="icon-arrow-right-1"></i>',
            onBeforeSlide: function (el) {
              const parent =  $(this).parents('[data-component=carouselVantaggi]');
              parent.find('.vantaggi__elementListContainer p').removeClass('active'); //rimuovo la classe active
              parent.find('.vantaggi__elementListContainer p').eq((el.getCurrentSlideCount() - 1)).addClass('active'); //aggiungo la classe active all'elemento
            }
          });

        });

        $(document).on('click', '[data-carousel]:not(.active)', function() {
          const index = $(this).data('carousel');
          $(this).parents('[data-component=carouselVantaggi]').find('.lSPager li').eq(index).trigger('click');
        }); 

      }

      if($('[data-component="newsBoxComponent"]').length > 0) {
        // styles to load
        styles = {
          "lightslider/css/lightslider": "lib"
        };
        application.loadStyle(styles);

        // scripts to load
        scripts = {
          "lightslider/js/lightslider" : "lib",
        };

        application.loadScript(scripts, function() {
          var options = {
            item: 3,
            autoWidth: false,
            adaptiveHeight: false,
            loop: false,
            enableDrag: true,
            enableTouch: true,
            freeMove: true,
            slideMargin: 15,
            controls: false,
            responsive: [{
                breakpoint: 1023,
                settings: {
                  item: 2
                }
            }, {
                breakpoint: 767,
                settings: {
                  item: 1
                }
            }]
          };
  
          $('[data-component="newsBoxComponent"] [data-type-component="genericBoxes"]').lightSlider(options);
        });

      }
    }
  }

  /**
   *
   * Setup responsive background image
   *
  */
  this.setupResponsiveBackgroundImage = function() {

    // get all the components
    var components = $('[data-bg-img-desktop]');

    if(components.length) {
      components.each( function() {
        // get desktop url link
        var $this = $(this);
        var imageURL = $this.attr('data-bg-img-desktop');
        // if mobile use mobile image
        if(application.isMobile()) {
          imageURL = $this.attr('data-bg-img-mobile');
        }
        // check if it's not empty
        if(imageURL != '') {
          $this.css("backgroundImage", "url('" + imageURL + "')");
        }
      });
    }

  }

  /**
   *
   * Setup responsive image
   *
  */
  this.setupResponsiveImage = function() {

    // get all the components
    var components = $('[data-img-desktop]');
    if(components.length) { 
      components.each( function() {
        // get desktop url link
        var $this = $(this);
        var imageURL = $this.attr('data-img-desktop');

        // if mobile use mobile image
        if(application.isMobile()) {
          imageURL = $this.attr('data-img-mobile');
        }

        // check if it's not empty
        if(imageURL != '') {
          $this.attr('src', imageURL);
        }
      });
    }
  }

  /**
   * Setup automatic confirm messages
   *
  */
  this.setupConfirmMessages = function() {

    // check app started
    if (application.appStart) return;

    // get messages
    var alertsConfirm = $(".alertConfirmMessage");

    // check messages
    if (alertsConfirm.length > 0) {

      // fetch messages list
      for (var index = 0; index < alertsConfirm.length; index++) {

        // active message
        var alert = $(alertsConfirm[index]);

        application.confirm(

          '<div class="container-generic-structure ' + alert.attr("data-section") + '">' + alert.attr("data-text") + '</div>',
          alert.attr("data-title"),
          function() {

            if (alert.attr("data-submit-link") != undefined && alert.attr("data-submit-link") != "") {
              window.location.href = prestitoObj.context + alert.attr("data-submit-link");
              return;
            }

            if (alert.attr("data-submitOnCancel") === "true") return;
            sumitcCallback(alert);
          
          },

          function() {

              if (alert.attr("data-submitOnCancel") === "true") {
                sumitcCallback(alert);
              }

          },

          alert.attr("data-submit"),
          alert.attr("data-cancel")

        );

        //Add custom class to the dialogs
        if ((alert.attr("data-section") !== "") && (alert.attr("data-section") !== undefined)) {
          $(".alertify .dialog").addClass(alert.attr("data-section"));
        }

      }

    }

    function sumitcCallback(alert) {

      if ($("*[data-component=" + alert.attr("data-section") + "]").length > 0) {

        var inputid = $("*[data-component=" + alert.attr("data-section") + "] form").data("inputid");
        $("#" + inputid).attr("value", "true");
        $("*[data-component=" + alert.attr("data-section") + "] form").submit();

      }

    }

  };

  /**
   * Manage confirm message
   *
  */
  this.confirm = function(message, title, callbackOK, callbackNO, labelOK, labelNO) {

    // default values
    title = (title !== undefined) ? "<h4>" + title + "<i class=\"icon-close\"></i></h4>" : "";
    message = (message !== undefined) ? message : "Default message";
    callbackOK = (callbackOK !== undefined) ? callbackOK : function() {};
    callbackNO = (callbackNO !== undefined) ? callbackNO : function() {};
    var theme = "withbuttons";
    if (labelOK == undefined && labelNO == undefined) {
        theme = "nobuttons"; //use "nobuttons" as theme for the no buttons modal version
    }
    labelOK = (labelOK !== undefined) ? labelOK : "OK";
    labelNO = (labelNO !== undefined) ? labelNO : "ANNULLA";

    // add message title
    message = title + message;

    confirm = alertify
        .theme(theme)
        .okBtn(labelOK)
        .cancelBtn(labelNO)
        .confirm(message, callbackOK, callbackNO);

    //focus on the first input of the modal

    if (!application.isMobile()) { // not on mobile
      if($(".alertify").find('input[type="text"]').length > 0) {

        setTimeout(function() {
          var firstInput = $(".alertify").find('input[type="text"]').get(0);
          if (firstInput && !$(".alertify").find('input[type="text"]').eq(0).hasClass('no-trigger')) {
            if (firstInput !== undefined) {
              firstInput.focus();
            }
          }
        }, 600);

      }
    }

  };

  /**
   * Manage notification
   *
  */
  this.notify = function(message, type, image, delay) {

    // default values
    message = (message !== undefined) ? message : "Default message";
    type = (type !== undefined) ? type : "";
    image = (image !== undefined) ? image : "";
    delay = (delay !== undefined) ? delay : 5000;

    // defaults
    alertify.delay(delay);
    alertify.closeLogOnClick(true);
    alertify.maxLogItems(3);
    alertify.setLogTemplate(function(input) {
        var template;

        if (image !== undefined && image !== "") {

          template = '<div class="imgContainer"><img src="' + image + '" /></div><div class="msgContainer">' + input + '</div><span class="closeText" aria-hidden="true"><i class="icon-close"></i></span>';

        } else {

          template = '<div class="msgContainer">' + input + '</div><span class="closeText" aria-hidden="true"><i class="icon-close"></i></span>';

        }

        return template;

    });

    this.setupNotificationMessages = function () {
      // check app started
      if (application.appStart) return;
      // get messages
      var messages = $(".notificationMessage");
      var messageArray = [];
      // check messages
      if (messages.length > 0) {
            // fetch messages list
            for (var index = 0; index < messages.length; index++) {
                // active message
                var message = $(messages[index]);
                if (messageArray.length > 0) {
                    if ($.inArray(message.html(), messageArray) < 0) {
                        messageArray.push(message.html());
                        // create notification
                        application.notify(message.html(), message.attr("data-type"), message.attr("data-image"), 20000);
                    }
                } else {
                    messageArray.push(message.html());
                    // create notification
                    application.notify(message.html(), message.attr("data-type"), message.attr("data-image"), 20000);
                }
            }
            //tracking Webtrekk attivazione 4+1 dal token
            if (messages.attr("data-image").includes("4_logo_square.png")) { //check to see if the message is for the 4+1 activation
                var registrationFromTokenWTS = sessionStorage.getItem('registrationFromTokenWTS');
                if (registrationFromTokenWTS !== null) {
                    if (registrationFromTokenWTS == "withActivation") {
                        sessionStorage.removeItem('registrationFromTokenWTS');
                    }
                }
            }
        }
    };

    if (type == "success") alertify.success(message);
    else if (type == "error") alertify.error(message);
    else alertify.log(message);

  };

  /**
   * Setup automatic notification messages
   *
   */
  this.setupNotificationMessages = function () {
    // check app started
    if (application.appStart) return;


    // get messages
    var messages = $(".notificationMessage");
    var messageArray = [];

    // check messages
    if (messages.length > 0) {

      // fetch messages list
      for (var index = 0; index < messages.length; index++) {

        // active message
        var message = $(messages[index]);
        if (messageArray.length > 0) {
          if ($.inArray(message.html(), messageArray) < 0) {
            messageArray.push(message.html());
            // create notification
            application.notify(message.html(), message.attr("data-type"), message.attr("data-image"), 20000);
          }
        } else {
          messageArray.push(message.html());
          // create notification
          application.notify(message.html(), message.attr("data-type"), message.attr("data-image"), 20000);
        }

      }

      //tracking Webtrekk attivazione 4+1 dal token
      if (messages.attr("data-image").includes("4_logo_square.png")) { //check to see if the message is for the 4+1 activation

        var registrationFromTokenWTS = sessionStorage.getItem('registrationFromTokenWTS');
        if (registrationFromTokenWTS !== null) {

          if (registrationFromTokenWTS == "withActivation") {

            sessionStorage.removeItem('registrationFromTokenWTS');

          }

        }

      }

    }

  };

  /**
   * Setup tooltip
   *
   */
  this.setupTooltip = function() {

    $('.tooltip-hover').popover();
    $('.tooltip-on-hover').popover({
      "html": true,
      "trigger": "hover"
    });

    //custom tooltip
    if($('[data-component=interestCalculatorComponent]').length > 0){
      $(".pianiContainer__infoBox .wrap_tooltip_socialLoan .tooltip-hover").hover(function(){
        $("body .popover").addClass("tooltipSimulator");
        console.log("tooltip");
      });
    }

  };


  /**
   * Setup Accordion
   *
   */
  this.setupAccordion = function() {

    // custom plugin
    let checkDevice;
    if (application.isDesktopXL()) {
      checkDevice = "DesktopXL";
    } else if (application.isDesktop()) {
      checkDevice = "Desktop";
    } else if (application.isTablet()) {
      checkDevice = "Tablet";
    } else if (application.isMobile()) {
      checkDevice = "Mobile";
    }

    // accordion available list for device
    let accordionNameAvailable = {
      DesktopXL: ['genericAccordion', ],
      Desktop: ['genericAccordion', ],
      Tablet: ['prova1', 'prova2', 'genericAccordion', 'accordionMobileTablet'],
      Mobile: ['storeResults', 'accordionMobile', 'genericAccordion', 'accordionMobileTablet']
    };

    var initAccordion = function() {

      var accordionTypeClicked = $('[data-accordion-type]');

      $.each(accordionTypeClicked, function(i) {
        if ($(this).attr('data-accordion-type') == 'parent') $(this).css('cursor', 'pointer');
      });

      // assign accordion to their presentation by device (es. accordion footer visible only on desktopXL/desktop...)
      $.each(accordionNameAvailable, function(device) {

        if (device == checkDevice) {
          accordionTypeClicked.off('click');
          for (var a = 0; a < accordionNameAvailable[device].length; a++) {
            accordionClicked(accordionNameAvailable[device][a]);
          }
        }

      });

      function accordionClicked(dataAccordionName) {

        // if i change device visual disable before click behaviours
        accordionTypeClicked.filter('[data-accordion-type="parent"][data-accordion-name="' + dataAccordionName + '"]').off('click');

        // enable click behaviours
        accordionTypeClicked.filter('[data-accordion-type="parent"][data-accordion-name="' + dataAccordionName + '"]').on('click', function(event) {

          for (var a = 0; a < event.target.attributes.length; a++) {
            // return if a child element is wrapped inside a parent element to avoid accordion closing
            if (event.target.attributes[a].value == 'child') return false;
          }

          var accordionType = $(this).attr('data-accordion-type');
          var accordionName = $(this).attr('data-accordion-name');

          // 2 types of accordion
          /*
            * .siblings()
            * <parent></parent>
            * <child></child>
            *
            * oppure
            *
            * .find()
            * <parent>
            * 	<child></child>
            * </parent
            *
            *
            */

          if ($(this).siblings('[data-accordion-type="child"][data-accordion-name="' + accordionName + '"]').length) {

            $(this).toggleClass('accordionActive');

            if (!application.isMobile()) $(this).siblings('[data-accordion-type="child"][data-accordion-name="' + accordionName + '"]').toggleClass('accordionOpened');
            else $(this).siblings('[data-accordion-type="child"][data-accordion-name="' + accordionName + '"]').toggleClass('accordionOpened');

          } else if ($(this).find('[data-accordion-type="child"][data-accordion-name="' + accordionName + '"]').length) {

            var parentAccordion = $(this);
            var childAccordion = $(this).find('[data-accordion-type="child"][data-accordion-name="' + dataAccordionName + '"]');

            accordionTypeClicked.filter('[data-accordion-type="parent"][data-accordion-name="' + dataAccordionName + '"]').not(parentAccordion).removeClass('accordionActive');
            accordionTypeClicked.filter('[data-accordion-type="child"][data-accordion-name="' + dataAccordionName + '"]').not(childAccordion).removeClass('accordionOpened');

            $(this).toggleClass('accordionActive');

            if (!application.isMobile()) $(this).find('[data-accordion-type="child"][data-accordion-name="' + accordionName + '"]').toggleClass('accordionOpened');
            else $(this).find('[data-accordion-type="child"][data-accordion-name="' + accordionName + '"]').toggleClass('accordionOpened');

          }

          //toggle text
          var triggeredText = $(this).attr('data-triggered-text');
          var initialText = $(this).attr('data-initial-text');

          if ((typeof triggeredText !== typeof undefined && triggeredText !== false) && (typeof initialText !== typeof undefined && initialText !== false)) {
            if ($(this).siblings().hasClass('accordionOpened')) {
              $(this).find(".accordion-text").text($(this).attr("data-triggered-text"));
            } else {
              $(this).find(".accordion-text").text($(this).attr("data-initial-text"));
            }
          }

          if ($(this).attr('data-accordion-one-way') === 'true') {
            if ($(this).prev('.abstract')) {
              $(this).prev('.abstract').hide();
            }
            $(this).hide();
          }

        });

      }
    };

    initAccordion();

  };


  /**
   * Setup select form
   *
  */
  this.setupSelectForm = function(skip) {

    // if select exists in page
    if (($('select').length > 0) || ($('.select').length > 0)) {

      if(!skip) {
        if (application.appStart) return;
      }

      // load styles
      var styles = {
        "selectric/css/selectric": "lib"
      };
      application.loadStyle(styles);

      // load scripts
      var scripts = {
        "selectric/js/selectric": "lib"
      };

      application.loadScript(scripts, function() {

        $('select, .select').not('[data-select-widget-volantino], [data-no-selectric]').selectric({
          maxHeight: 250,
          disableOnMobile: false,
          nativeOnMobile: false,
          arrowButtonMarkup: '<i class="icon-arrow-top selectric-icn"></i>',
          labelBuilder: function(currItem) {

            var classItem = $(currItem)[0].element[0].className;
            if (classItem == "placeholder") {
              return currItem.text;
            } else {
              return "<span>" + currItem.text + "</span>";
            }

          },
          optionsItemBuilder: function(currItem) {

            var attributesUrlTagCurrItem = $(currItem)[0].element[0].getAttribute("data-url-tag");
            var attributesUrlIconCurrItem = $(currItem)[0].element[0].getAttribute("data-url-icon");
            if (attributesUrlTagCurrItem) {
              return '<a href="' + attributesUrlTagCurrItem + '">' + currItem.text + '</a>';
            } else if (attributesUrlIconCurrItem) {
              return '<div class="icon-scope icon-filter"><img class="img-responsive" src="' + attributesUrlIconCurrItem + '" /></div>' + currItem.text;
            } else {
              return "<div class='" + $(currItem.element).attr("class") + "'><span>" + currItem.text + "</span></div>";
            }

          }
        });
      });
    }

  };

  /**
   * Setup charts
   *
   */
  this.setupCharts = function() {

    if (!application.appStart) {
      if (($("*[data-chart]").length > 0) || ($("*[data-chartjs]").length > 0)) {

        // log
        application.console.debug("Detected chart in page");

        // scripts to load
        var scripts = {
          // "d3pie/js/d3" : "lib",
          "d3pie/js/d3pie": "lib",
          "chartjs/js/chart.bundle": "lib",
          "charts": "core"
        };

        application.loadScript(scripts, function() {

          // init component
          application.charts = Prestito.Charts.getInstance();
          application.charts.init();

        });
      }
    }

  };


  /* --------------------- UTILITIES ------------------------------------------------------------------------- */

  /**
   * Console js management
   * If browser doesn't support js console, ingores the feature
   *
  */
  this.console = function(msg, verbose) {
    msg = "[PRESTITO] " + msg;
    if (window.console && application.showConsole === true) {
      if (verbose === 'log') {
        window.console.log(msg);
      } else if (verbose === 'error') {
        window.console.error(msg);
      } else if (verbose === 'debug') {
        if (window.console.debug) {
          window.console.debug(msg);
        }
      } else {
        if (window.console.debug) {
          window.console.debug(msg);
        }
      }
    }
  };
  this.console.log = function(msg) {
    application.console(msg, "log");
  };

  this.console.debug = function(msg) {
    application.console(msg, "debug");
  };
  this.console.error = function(msg) {
    application.console(msg, "error");
  };
  this.console.clear = function() {
    if (window.console && application.showConsole === true) {
      console.clear();
    }
  };

  /**
   * Log data layer object
   *
  */
  this.logDataObject = function() {
    application.console.debug("Data Object (prestitoObj): " + JSON.stringify(prestitoObj, null, 4));  // log
  };

  /**
   * Log cookies
   *
  */
  this.logCookie = function() {
    if (this.showCookie) {
      application.console.debug("Cookies: " + JSON.stringify($.cookie(), null, 4)); // log
    }
  };

  /**
   * Shortcut loading css styles
   *
  */
  this.loadStyle = function(inFileName, callback) {

    application.fileLoad(inFileName, "style", function() {
      // onload style callback
      if (callback) callback(); 
    });

  };

  /**
   * Shortcut loading js script
   *
  */
  this.loadScript = function(inFileName, callback) {

    application.fileLoad(inFileName, "script", function() {
      if (callback) callback(); // onload script callback
    });

  };

  /**
   * Loading generic file
   *
   */
  this.fileLoad = function(inFileName, type, callback) {

    // set parameters to load correct loading block
    var jobName = "loadScriptJob_" + Math.random();

    // set loading job
    window.jobsLoading[jobName] = {};
    window.jobsLoading[jobName].fileLoadNum = 0;
    window.jobsLoading[jobName].fileTotalNum = Object.size(inFileName);
    window.jobsLoading[jobName].jobEnded = false;
    window.jobsLoading[jobName].watch("jobEnded", function(id, oldval, newval) {
      application.finalizeApplication();
    });

    // fetch the loading file list
    for (var fileToLoad in inFileName) {

      var fileOrigin = inFileName[fileToLoad]; // file origin (core/lib)
      var filePath = prestitoObj.context; // pathBase

      // get complete path based on type file and enviroment
      if (fileOrigin == "lib" || fileOrigin == "core") {

          if (fileOrigin == "lib") {
            filePath += prestitoObj.pathLIBS + "/" + fileToLoad;
            if (application.env != "devel" && !application.forceExpandedAssets) filePath += ".min";
          } else if (fileOrigin == "core") {

            if (application.env != "devel" && !application.forceExpandedAssets) {
              filePath += prestitoObj.pathBUILD + "/" + fileToLoad + ".min";
            } else {

              if (type == "style") filePath += prestitoObj.pathCSS + "/";
              else if (type == "script") filePath += prestitoObj.pathJS + "/";

              filePath += fileToLoad;
            }
          }

        if (type == "style") filePath += ".css?ver=1656412331342";
        else if (type == "script") filePath += ".js?ver=1656412331342";

      } else if (fileOrigin == "external") {

        filePath = fileToLoad;
      
      }

      // init file load
      var aScript;

      // check loading filetype
      switch (type) {

        case "style":

          if (application.skipLoadFiles) {
            callback();
            return;
          }

          // create a dom element to hold the code
          aScript = document.createElement('link');
          aScript.rel = 'stylesheet preload';
          aScript.type = 'text/css';
          aScript.setAttribute('as', 'style');
          aScript.href = filePath;
          aScript.nameJob = jobName;

          // event load script
          aScript.addEventListener("load", function() {

              // update loaded file number
              window.jobsLoading[this.nameJob].fileLoadNum++;
              if (window.jobsLoading[this.nameJob].fileLoadNum === window.jobsLoading[this.nameJob].fileTotalNum) {
                callback();
                window.jobsLoading[this.nameJob].jobEnded = true;
              }

          });

          // append the code to the dom
          document.getElementsByTagName('head')[0].appendChild(aScript);

          break;

        case "script":

          if (application.skipLoadFiles) {
            callback();
            return;
          }

          // create a dom element to hold the code
          aScript = document.createElement('script');
          aScript.src = filePath;
          aScript.setAttribute('defer', '');
          aScript.nameJob = jobName;

          // event load script
          aScript.addEventListener("load", function() {

            // update loaded file number
            window.jobsLoading[this.nameJob].fileLoadNum++;
            if (window.jobsLoading[this.nameJob].fileLoadNum === window.jobsLoading[this.nameJob].fileTotalNum) {
              callback();
              window.jobsLoading[this.nameJob].jobEnded = true;
            }

          });

          // append the code to the dom
          document.getElementsByTagName('head')[0].appendChild(aScript);
          break;

      }
    }

  };


  /**
   * Finalize application
   *
  */
  this.finalizeApplication = function() {

    application.jobsEnded++; // update jobs ended number

    // check total jobs
    if (application.jobsEnded == Object.size(window.jobsLoading)) {
      // log
      application.console.log("Application correctly finalized");
      // set app started
      application.appStart = true;
    }

  };

  this.isIE = function () {

    var myNav = navigator.userAgent.toLowerCase();

    return (myNav.indexOf('msie') != -1) ? true : false;

  };

  this.getIEVersion = function () {

    var ieVersion = 0;

    if (application.isIE()) {

      var myNav = navigator.userAgent.toLowerCase();

      ieVersion = parseInt(myNav.split('msie')[1]);

    }

    return ieVersion;

  };



  /**
   * Ajax loader management
   *
  */
  this.ajaxLoader = function(stato, target, type) {

    var targetSpinner = $("body");

    if (target == "no-loader") return;
    if (target == null || target == undefined) {

      if (application.ajaxLoaderTarget) {
        targetSpinner = $(application.ajaxLoaderTarget);
      }

    } else {
      targetSpinner = $(target);
    }

    // spinner open / close
    if (stato) {

      var targetH, targetW, position;

      if (targetSpinner.is('body')) {

        targetH = $(window).outerHeight() + 'px';
        targetW = $(window).outerWidth() + 'px';

        position = "fixed";

      } else {

        targetH = targetSpinner.outerHeight() + 'px';
        targetW = targetSpinner.outerWidth() + 'px';
        position = "absolute";

        if (targetSpinner.css("position") == undefined || targetSpinner.css("position") == "") {
          if ((!application.isIE() || application.getIEVersion() > 9) && targetSpinner.css("display") == "inline-block") {
            targetSpinner.attr("style", "position: relative; display: inline-block;");
          } else {
            targetSpinner.attr("style", "position: relative; display: block;");
          }
        }

      }

      // if spinner exists on the global search page, don't create another one
      if ($('body.globalSearchPage').length > 0) {
        if ($('body.globalSearchPage.loader').length > 0) {
            if ($('body.globalSearchPage .loader-overlay').length > 0) {
              return;
            }
        } else {
          return;
        }
      }

      var spinnerSize;
      var spinner;
      if (type == "btnPrimary") {

        spinnerSize = 5;
        targetSpinner.append('<div class="loader-overlay" style="position: ' + position + '; top: 0; left: 0; width: ' + targetW + '; height: ' + targetH + '; background-color: rgba(255, 0, 0, 1);z-index:1000002;"></div>');

      } else if (type == "small") {

        spinnerSize = 5;
        targetSpinner.append('<div class="loader-overlay" style="position: ' + position + '; top: 0; left: 0; width: ' + targetW + '; height: ' + targetH + '; background-color: rgba(255, 255, 255, 1);z-index:1000002;"></div>');

      } else {

        spinnerSize = 15;
        targetSpinner.append('<div class="loader-overlay" style="position: ' + position + '; top: 0; left: 0; width: ' + targetW + '; height: ' + targetH + '; background-color: rgba(255, 255, 255, 0.7);z-index:1000002;"></div>');

      }

      targetSpinner.find(".loader-overlay").fadeIn("fast");

      if (type == "btnPrimary") {

        spinnerSize = 5;
        spinner = '<div class="spinner btnPrimary"><div class="bounce1" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce2" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce3" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce4" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div></div>';

      } else if (type == "small") {

        spinnerSize = 5;
        spinner = '<div class="spinner small"><div class="bounce1" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce2" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce3" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce4" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div></div>';

      } else {

        spinnerSize = 15;
        spinner = '<div class="spinner"><div class="bounce1" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce2" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce3" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div><div class="bounce4" style="width: ' + spinnerSize + 'px; height: ' + spinnerSize + 'px"></div></div>';

      }

      $(".loader-overlay", targetSpinner).append(spinner);

    } else {

      if (!application.ajaxForceLoader) {
        if ($('body.globalSearchPage.loader').length > 0) {
          return;
        }

        targetSpinner.find(".loader-overlay", targetSpinner).fadeOut("fast");
        targetSpinner.find(".spinner", targetSpinner).remove();
        targetSpinner.find(".loader-overlay", targetSpinner).remove();

        application.ajaxLoaderTarget = null;

      }

    }

    return true;

  };

  /**
   * Check device MOBILE
   *
  */
  this.isMobile = function() {

    return window.mqMobile.matches;

  };

  /**
   * Check device TABLET (portrait)
   *
  */
  this.isTablet = function() {

    return window.mqTablet.matches;

  };

  /**
   * Check DESKTOP
   *
  */
  this.isDesktop = function() {

    return window.mqDesktop.matches;

  };

  /**
   * Check DESKTOP XL
   *
  */
  this.isDesktopXL = function() {

    return window.mqDesktopxl.matches;

  };

  
  /**
   * Check DEVEL environment
   *
  */
  this.isDevel = function() {

    return application.env == "devel";

  };

  /**
   * Check TEST environment
   *
  */
  this.isTest = function() {

    return application.env == "test";

  };

  /**
   * Check PROD environment
   *
  */
  this.isProd = function() {

    return application.env == "prod";

  };

  /**
   * Check Magnolia EditMode
   *
  */
  this.isEditMode = function() {

    return prestitoObj.editMode;

  };


  /* --------------------- INIT COMPONENTS ------------------------------------------------------------------------- */


  /**
   * Init components by type
   *
  */
    this.initComponentsByType = function() {

    // log
    application.console.log("Init components in page");

    var scripts;
    var styles;

    
    // MINI BANNER component
    if ($("*[data-component=miniBanner]").length > 0) {

      // log
      application.console.debug("Detected component: MINI BANNER");

      $("*[data-component=miniBanner]").each(function(index, el) {

        const thisMini = $(this);

          const titleNotify = thisMini.attr('data-bookingid');  // get message title
          const getCookieNotify = $.cookie("cookieNotify-" + titleNotify); // get message close cookie
          const cookieDuration = thisMini.attr('data-cookie-duration'); // get cookie duration

          thisMini.on('click', '.mbClose', function(event) { //hide the mini banner if clicked on close

            event.preventDefault();
            if (cookieDuration == undefined || cookieDuration == "") cookieDuration = 30;

            $.cookie("cookieNotify-" + titleNotify, "closed", { expires: parseInt(cookieDuration), path: "/" });

            thisMini.hide();

          });

          // show the minibanner if the cookie's status is OK
          if (getCookieNotify == undefined || getCookieNotify == '' || getCookieNotify != "closed") {
            thisMini.removeClass('hide');
          }

      });
    }

  
    var styles = {
      "bootstrap-slider/css/bootstrap-slider": "lib",
    };
    application.loadStyle(styles);

    var scripts = {
      "bootstrap-slider/js/bootstrap-slider": "lib",
    };

    application.loadScript(scripts, function() {
      application.prestitoVincolato();
      application.sliderSimulator();
    });

  };  

  this.associaIban = function () {
    application.console.log("Init component: Associa IBAN");
    if (application.appStart) return;
    $(window).on("resize", function () {
      const step = $('[data-breadcrumb-vincolato-step]').data('breadcrumb-vincolato-step');
      if (step != "") {
        $('.breadcrumb_progress').css('width', $('.' + step + ' .breadcrumb__step')[0].offsetLeft);
      }
    });
    $(window).trigger('resize');
    if ($('[data-component="associazione-iban-step-1 importa"]').length > 0) {
      // get the booklet value from the url
      var url = window.location.search;
      var urlParams = new URLSearchParams(url);
      var booklet = urlParams.get('booklet');

      // seleziona automaticamente l'iban se solo uno
      var label = $('.wrap-label').find('label');
      if (label.length == 1) {
        setTimeout(function () {
          label.find('input').prop('checked', true);
          label.find('input').trigger('change');
        }, 500);
      }


      var elencoIbanDaAssociare = [];
      $('.wrap-label input[type="checkbox"]').each(function () {
        var iban = $(this).val();
        if (elencoIbanDaAssociare.includes(iban)) {
          $(this).prop('checked', true);
        } else {
          $(this).prop('checked', false);
        }
      });
      $('.wrap-label input[type="checkbox"]').on('change', function () {
        var iban = $(this).val();
        if ($(this).is(':checked')) {
          elencoIbanDaAssociare.push(iban);
        } else {
          elencoIbanDaAssociare = elencoIbanDaAssociare.filter(function (item) {
            return item !== iban;
          });
        }
        if (elencoIbanDaAssociare.length > 0) {
          $('.selectBooklet__btnContainer .btn-primary').attr('disabled', false);
        } else {
          $('.selectBooklet__btnContainer .btn-primary').attr('disabled', true);
        }
      });
      // add click event to the button of data-component="associazione-iban-step-1 importa"
      $('[data-component="associazione-iban-step-1 importa"] .selectBooklet__btnContainer .btn-primary').on('click', function () {
        // iterate over the checkboxes and check the ones that are in the array
        const link = $('[data-link-success]').data('link-success') + `?booklet=${booklet}&ibans=${elencoIbanDaAssociare}`;
        window.open(link, "_self");
      }
      );
    }
    if ($('[data-component="associazione-iban-step-2 importa"]').length > 0) {
      var urlParams = new URLSearchParams(window.location.search);
      let elencoIbanDaAssociare = urlParams.get('ibans').split(',') || urlParams.get('ibans');
      let booklet = urlParams.get('booklet');
      let emailId = $('[data-email]').data('email');
      let nRichiesta = $('[data-nrichiesta]').data('nrichiesta');
      $(document).on('click', '[data-conferma-otp]', function (e) {
        e.preventDefault();
        application.showOtpManageModal(null, function() {
          $.ajax({
            'method': 'POST',
            'url': prestitoObj.context + `/socialLoanServlet/?operation=confirmOperationMulti&emailId=${emailId}&richiesta=${nRichiesta}&booklet=${booklet}&ibans=${elencoIbanDaAssociare}`,
            'success': function (res) {
              if (res) {
                window.location.href = $('[data-link-success]').data('link-success');
              }
              if (res == false) {
                application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);
              }
            },
            'error': function () {
              application.notify("Errore<br/>Per motivi tecnici abbiamo riscontrato un problema e non è stato possibile procedere con l'operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", 'error', undefined, 5000);
            }
          });
        });
      });
    }
    if ($('.containerPdf').length > 0) {
      const download_pdf = $('.containerPdf').data('pdf');
      if (download_pdf) {
        application.generatePDF(download_pdf);
      }
      $(document).on('click', '[data-print-pdf]', function () {
        printJS({ printable: download_pdf, type: 'pdf', base64: true });
      });
      $(document).on('click', '[data-zoom-in-pdf]', function () {
        zoomPdf += 0.15;
        application.generatePDF(download_pdf);
      });
      $(document).on('click', '[data-zoom-out-pdf]', function () {
        if (zoomPdf >= 1.65) { //se zoom è >= 1.25 sottraggo, altrimenti imposto il minimo zoom possibile, 1
          zoomPdf -= 0.15;
          application.generatePDF(download_pdf);
        } else {
          zoomPdf = 1.5;
        }
      });
    }
    // modale che si attiva quando viene cliccato il logo nella header per tornare alla home
  /* if ($('[data-m-abbandono]').length > 0) {
    $(document).on('click', '[data-m-abbandono]', function () {
      const link = $(this).data('m-confirm-link') || `${prestitoObj.context}/area-libretto/situazione-libretto.html`;
      application.confirm(
        `<div class='container-generic-structure'>
          <div class='textContainer'>
            ${$(this).data('m-text') || 'Sei sicuro di voler abbandonare l\'operazione?'}
          </div>
        </div>`,
        $(this).data('m-title') || 'Attenzione',
        function () {
          window.location.href = link;
        },
        function () { },
        $(this).data('m-confirm') || 'Conferma',
        $(this).data('m-annulla') || 'Annulla',
      );
    });
  } */
  }

  this.toggleAccordion = function () {
    $('.card-accordion').click(function () {
      $(this).toggleClass('active');
    });
  }

  //madals for activating card process
  this.activateCard = function() {
      
    // declaration operation
    var richiestaAttivazionePagamento = 'enablePaymentCard';
    var confermaAttivazionePagamento = 'confirmEnablePaymentCard';
    var richiestaDisattivazionePagamento = 'disablePaymentCard';
    var confermaDisattivazionePagamento = 'confirmDisablePaymentCard';
    var richiestaMassimali = 'paymentCardCeilings';
    var richiestaCambioPin = 'changeCardPin';
    /**
       * @function initEnabledObj
       * @return {object} {initialize object for ability card}
       */
    this.initEnabledObj = function() {
    return {
        'ean_card': '',
        'nro_libretto': '',
        'massimale': '',
        'emailId': '',
        'pin': '',
        'confirmPin': '',
        'pin_attuale': '',
        'pin_dimenticato': '',
        'tipo_richiesta': '',
        'firstOtp': false,
        'secondOtp': false
      };
    };
    //activate modal pre activate payment
    $(document).on("click", "[data-preactivatepayment=OK]", function() {

      var target = $(this);

      application.objCardEnabled = application.initEnabledObj();

      target.attr("disabled", "true");
      setTimeout(function() {
          target.removeAttr("disabled");
      }, 2000);

      var modalTitle = target.attr('data-title') || "";
      var modalSubTitle = target.attr('data-subtitle') || "";
      var modalImg = target.attr('data-image') || "";
      var modalText = target.attr('data-description') || "";
      var modalOK = target.attr('data-btnok') || "OK";
      var modalCancel = target.attr('data-btncancel') || "Annulla";
      var ean_card = target.attr('data-eancard') || "";
      var booklet = target.attr('data-nroLibretto') || "";

      application.objCardEnabled.nro_libretto = booklet;
      application.objCardEnabled.tipo_richiesta = 'A';
      application.objCardEnabled.ean_card = ean_card;
      application.objCardEnabled.emailId = target.attr('data-email') || "";

      var modalText3 = '<div class="input-wrapper">\
          <div class="row">\
              <div class="col-md-12">\
              ' + modalSubTitle + 
              '</div>\
          </div>\
          <div class="row">\
              <div class="col-md-2">\
                  <img src="' + modalImg + '"/>\
              </div>\
              <div class="col-md-10 description-text">\
                 ' + modalText + 
              '</div>\
          </div>\
      </div>';

      var disabled = target.attr('disabled') || "";

      if (!disabled !== "true") {
        $("body .alertify").remove();
        application.confirm(
          "<div class='container-generic-structure setpin-modal-content'>\
              <div class='textContainer'>" + modalText3 + "</div>\
          </div>",

          modalTitle,
          function() {
              application.setMassimal();
          },
          function() {
              application.confirmAnnul(function() {
                  $("[data-preactivatepayment=OK]").trigger("click");
              });
          },
          modalOK,
          modalCancel
        );

        //add custom class to the modal
        $("body .dialog").addClass("managecards800 nav modal-fullscreen");

        $(".managecards800 .icon-close").off("click");
        $(document).on("click", ".managecards800 .icon-close", function() {
            $("body .alertify").remove();
            application.confirmAnnul(function() {
                $("[data-preactivatepayment=OK]").trigger("click");
            });
        });
      }
    });

    this.typePaymentCard = function(operation, data_json, callback) {
      var data = {};
      if (operation == richiestaAttivazionePagamento) {
          if (data_json && data_json.ean_card) {
              data.card = data_json.ean_card;
          }
          if (data_json && data_json.nro_libretto) {
              data.booklet = data_json.nro_libretto;
          }
          if (data_json && data_json.pin) {
              data.pin = data_json.pin;
          }
          if (data_json && data_json.confirmPin) {
              data.confirmPin = data_json.confirmPin;
          }
          if (data_json && data_json.tipo_massimale) {
              data.ceilingCode = data_json.tipo_massimale;
          }
      } else if (operation == confermaAttivazionePagamento) {
          if (data_json && data_json.ean_card) {
              data.card = data_json.ean_card;
          }
          if (data_json && data_json.nro_libretto) {
              data.booklet = data_json.nro_libretto;
          }
          if (data_json && data_json.nro_richiesta) {
              data.requestNumber = data_json.nro_richiesta;
          }
          if (data_json && data_json.emailId) {
            data.emailId = data_json.emailId;
          }
      } else if (operation == richiestaDisattivazionePagamento) {
          if (data_json && data_json.ean_card) {
              data.card = data_json.ean_card;
          }
          if (data_json && data_json.nro_libretto) {
              data.booklet = data_json.nro_libretto;
          }
      } else if (operation == confermaDisattivazionePagamento) {
          if (data_json && data_json.ean_card) {
              data.card = data_json.ean_card;
          }
          if (data_json && data_json.nro_richiesta) {
              data.requestNumber = data_json.nro_richiesta;
          }
          if (data_json && data_json.nro_libretto) {
              data.booklet = data_json.nro_libretto;
          }
      } else if (operation == richiestaMassimali) {
          if (data_json && data_json.tipo_massimale) {
              data.ceilingsType = data_json.tipo_massimale;
          } else {
              data.ceilingsType = 'L';
          }
      } else if (operation == richiestaCambioPin) {
          if (data_json && data_json.ean_card) {
              data.card = data_json.ean_card;
          }
          if (data_json && data_json.pin) {
              data.pin = data_json.pin;
          }
          if (data_json && data_json.confirmPin) {
              data.confirmPin = data_json.confirmPin;
          }
          if (data_json && data_json.pin_attuale) {
              data.currentPin = data_json.pin_attuale;
          }
          if (data_json && data_json.pin_dimenticato) {
              data.pinForgot = data_json.pin_dimenticato;
          }
      }

      $.ajax({
        'target': "body",
        'url': prestitoObj.context + '/socialLoanServlet/?operation=' + operation,
        'data': data,
        'method': 'POST',
        'success': function(res) {
            callback(res);
        },
        'error': function() {
            callback(null);
        }
      });
    }
  

    /**
         * Activate modal set massimal
         *
         */
    this.setMassimal = function(booklet, option_checked) {

      var target = $("[data-setmassimal]");

      var modalTitle = target.attr('data-title') || "";
      var modalSubTitle = target.attr('data-subtitle') || "";
      var modalText = target.attr('data-description') || "";
      var tipoMassimale = target.attr('data-type-card');
      var radioFirstCol = "";
      var radioSecondCol = "";

      var data_json = {
          tipo_massimale: tipoMassimale
      }

      application.typePaymentCard(richiestaMassimali, data_json, function(res) {
        if(res && res.info_massimali_list) {
          if(res.info_massimali_list && res.info_massimali_list.info_massimali) {
            var array_massimali = res.info_massimali_list.info_massimali;
            for(var i=0; i<array_massimali.length; i++) {
              var code_massimale = array_massimali[i].codice_massimale;
              var checked_day = '';
              if (code_massimale == option_checked) checked_day = 'checked';
              var giorn = array_massimali[i].max_gg_spesa;
              var mensil = array_massimali[i].max_mens_spesa;
              if (i < (array_massimali.length / 2)) {
                radioFirstCol += '<div class="radio radio-inline">\
                    <input type="radio" id="massimale' + i + '" name="massimale" value="' + code_massimale + '" ' + checked_day + '/>\
                    <label for="massimale' + i + '">Giornaliero <strong>' + giorn + ' €</strong><br/>Mensile <strong>' + mensil + ' €</strong></label>\
                </div>';
              } else {
                radioSecondCol += '<div class="radio radio-inline">\
                    <input type="radio" id="massimale' + i + '" name="massimale" value="' + code_massimale + '" ' + checked_day + '/>\
                    <label for="massimale' + i + '">Giornaliero <strong>' + giorn + ' €</strong><br/>Mensile <strong>' + mensil + ' €</strong></label>\
                </div>';
              }
            }
          }
      
          var modalOK = target.attr('data-btnok') || "OK";
          var modalCancel = target.attr('data-btncancel') || "Annulla";
          var massimalContent = '<div class="input-wrapper">\
              <label class="hide"></label>\
              <input class="hide" type="hidden"/>\
              <div class="container_massimal row">\
                  <div class="col-md-6 col-sm-12 col-xs-12">' +
                  radioFirstCol +
                  '</div>\
                  <div class="col-md-6 col-sm-12 col-xs-12">' +
                  radioSecondCol +
                  '</div>\
              </div>\
          </div>';
  
          $("body .alertify").remove();
          application.confirm(
              "<div class='container-generic-structure setpin-modal-content'>\
                  <div class='textContainer'><div class='marginBottom20 subtitle-text'>" + modalSubTitle + "</div> <div class='marginBottom20'>" + modalText + "</div>" + massimalContent + "</div>\
              </div>",
              modalTitle,
              function() {
                  var option_check = $('[name="massimale"]:checked').val();
                  application.objCardEnabled.tipo_massimale = option_check;
                  application.setPin();
              },
              function() {
                  var option_check = $('[name="massimale"]:checked').val();
                  application.confirmAnnul(function() {
                      application.setMassimal(booklet, option_check);
                  });
              },
              modalOK,
              modalCancel
          );
  
          //add custom class to the modal
          $("body .dialog").addClass("managecards800 nav modal-fullscreen");

          var thisModal = $(".setpin-modal-content").parents(".dialog");
          var thisOK = thisModal.find("button.ok");
          if(option_checked == undefined) thisOK.prop("disabled", true);

          $(document).on("change", "[name='massimale']", function() {
              var radioMassimal = $(this).val() || "";
              application.objCardEnabled.tipo_massimale = radioMassimal;
              if (radioMassimal !== "") {
                  thisOK.prop("disabled", false);
              } else {
                  thisOK.prop("disabled", true);
              }
          });
  
          $(".managecards600 .icon-close").off("click");
          $(document).on("click", ".managecards600 .icon-close", function() {
              var option_check = $('[name="massimale"]:checked').val();
              $("body .alertify").remove();
              application.confirmAnnul(function() {
                  application.setMassimal(booklet, option_check ? option_check : '');
              });
          });

        } else {
            application.notify("<strong>Errore</strong><br>Per motivi tecnici, abbiamo riscontrato un problema e non è stato possibile procedere con l’operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", "error", "", 10000);
        }
      }); 
    };

      /**
         * Activate modal set PIN
         *
         */
    this.setPin = function(pinCode1, confirmPinCode2) {
      pinCode1 = pinCode1 || '';
      confirmPinCode2 = confirmPinCode2 || '';
      application.restorePinIconCLose = pinCode1;
      application.restoreConfirmPinIconCLose = confirmPinCode2;
      if(application.objCardEnabled.tipo_massimale == '') {
          application.setMassimal();
          return;
      }

      var target = $("[data-setpin]");
      var modalTitle = target.attr('data-title') || "";
      var modalSubTitle = target.attr('data-subtitle') || "";
      var modalText = target.attr('data-description') || "";
      var modalOK = target.attr('data-btnok') || "OK";
      var modalCancel = target.attr('data-btncancel') || "Annulla";
      var pinlabel = target.attr('data-pinlabel') || "";
      var pin2label = target.attr('data-pin2label') || "";
      var pinplaceholder = target.attr('data-pinplaceholder') || "";
      var pin2placeholder = target.attr('data-pin2placeholder') || "";
      var pinerror = target.attr('data-pinerror') || "";

      var input_code_chrome = '';
      var isChrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
      if(isChrome) {
          input_code_chrome = 'class="circle_chrome" type="text"';
      } else {
          input_code_chrome = 'type="password"';
      }

      var pincontent = '<div class="input-wrapper">\
          <label class="hide"></label>\
          <input class="hide" type="password"/>\
          <div class="row">\
              <div class="col-md-6 col-sm-12">\
                  <div class="hide pinerror required-label">\
                      <div class="icn"><i class="icon-alert"></i></div>\
                      <div>\
                          <xsmall>' + pinerror + '</xsmall>\
                      </div>\
                  </div>\
                  <div class="input-container pin-container">\
                      <div class="input-text marLeft45">\
                          <label for="code1">' + pinlabel + '</label> <i class="leftIcon icon-password-1"></i><input ' + input_code_chrome + ' maxlength="5" id="code1" name="code1" pattern="[0-9]*" inputmode="numeric" autocomplete="off" value="'+pinCode1+'" placeholder="' + pinplaceholder + '" /></label>\
                      </div>\
                      <div class="show-password-icon" title="Visualizza / nascondi pin"></div>\
                  </div>\
              </div>\
              <div class="col-md-6 col-sm-12">\
                  <div class="hide pinerror required-label">\
                      <div class="icn"><i class="icon-alert"></i></div>\
                      <div>\
                          <xsmall>' + pinerror + '</xsmall>\
                      </div>\
                  </div>\
                  <div class="input-container pin-container">\
                      <div class="input-text marLeft45">\
                          <label for="code2">' + pin2label + '</label> <i class="leftIcon icon-password-1"></i><input ' + input_code_chrome + ' maxlength="5" id="code2" name="code2" pattern="[0-9]*" inputmode="numeric" autocomplete="off" value="'+confirmPinCode2+'" placeholder="' + pin2placeholder + '" /></label>\
                      </div>\
                      <div class="show-password-icon" title="Visualizza / nascondi pin"></div>\
                  </div>\
              </div>\
          </div>\
      </div>';

      $("body .alertify").remove();

      // toggle the class .circle_chrome clicking on .show-password-icon
      $(document).on("click", ".show-password-icon", function () {
          $(this).prev().find("input").toggleClass("circle_chrome");
          $(this).toggleClass("active");
      });


      application.confirm(
          "<div class='container-generic-structure setpin-modal-content'>\
              <div class='textContainer'><div class='marginBottom20 subtitle-text'>" + modalSubTitle + "</div> <div class='marginBottom20'>" + modalText + '</div>' + pincontent + "</div>\
          </div>",
          modalTitle,
          function() {
              if($('#code1').val() == $('#code2').val() && $('#code1').val().length == 5) {
                  application.objCardEnabled.pin = $('#code1').val();
                  application.objCardEnabled.confirmPin = $('#code2').val();
                  application.typePaymentCard(richiestaAttivazionePagamento, application.objCardEnabled, function(res) {
                      if(res) {
                          if(res.info_attivazione_pagamento && res.info_attivazione_pagamento && res.info_attivazione_pagamento.nro_richiesta) application.objCardEnabled.nro_richiesta = res.info_attivazione_pagamento.nro_richiesta;
                          if(res.info_attivazione_pagamento && res.info_attivazione_pagamento.stream) application.pdfRecapPayment('', res.info_attivazione_pagamento.stream, application.objCardEnabled);
                      } else {
                          application.notify("<strong>Errore</strong><br>Per motivi tecnici, abbiamo riscontrato un problema e non è stato possibile procedere con l’operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", "error", "", 10000);
                      }
                  });
              }
          },
          function() {
              var restorePin = $('#code1').val();
              var restoreConfirmPin = $('#code2').val();
              application.confirmAnnul(function() {
                  application.setPin(restorePin, restoreConfirmPin);
              });
          },
          modalOK,
          modalCancel
      );

      //add custom class to the modal
      $("body .dialog").addClass("managecards800 nav modal-fullscreen");


      var thisModal = $(".setpin-modal-content").parents(".dialog");
      var thisOK = thisModal.find("button.ok");
      if($('#code1').val() == $('#code2').val() && $('#code1').val().length == 5) {
          thisOK.prop("disabled", false);
      } else {
          thisOK.prop("disabled", true);
      }

      $(document).on('change', '#code1,#code2', function() {
          application.restorePinIconCLose = $('#code1').val();
          application.restoreConfirmPinIconCLose = $('#code2').val();
      });

      $(document).on("input", "#code1,#code2", function(e) {
        $(this).val($(this).val().replace(/[^0-9]/g, '')); //allow only numbers
        var p1 = $("#code1").val();
        var p2 = $("#code2").val();
        application.samePinCheck(p1, p2, thisOK);
      });

      $(".managecards600 .icon-close").off("click");
      $(document).on("click", ".managecards600 .icon-close", function() {
          $("body .alertify").remove();
          application.confirmAnnul(function() {
              application.setPin(application.restorePinIconCLose, application.restoreConfirmPinIconCLose);
          });
      });

    };

    //scroll detect fn
    this.scrollDetect = function(){
      //first, disable the submit btn
      $(".container_btn_flex.pdfStyle .ok").addClass("disabled"); 

      //check the scroll position
      $("#pdfIframe").on("scroll", function(){

          //manage the shadow
          if($(this).scrollTop() >= 1) { 
              $(".shadow-modal").addClass("active");    
          } else {
              $(".shadow-modal").removeClass("active");  
          }

          //manage the submit btn
          if($(this).scrollTop()+$(this).height()+100 >= $(this)[0].scrollHeight) {
              $(".container_btn_flex.pdfStyle .ok").removeClass("disabled");    
          }

      });	

    };

    /**
         * @function pdfRecapPayment - Open modal with document PDF
         * @param  {string} pdf_type type of pdf ('', '-article','-disabled')
         * @param  {pdf} download_pdf pdf on view
         * @param  {json} data_json param pass to api call
         * @return {null} null
         */
    this.pdfRecapPayment = function(pdf_type, download_pdf, data_json) {
      data_json = data_json||null;
      download_pdf = download_pdf||null;
      application.pdf_type = pdf_type || '';
      var target = $("[data-pdfrecap" + application.pdf_type + "]");
      var modalTitle = target.attr('data-title') || "";
      var modalSubTitle = target.attr('data-subtitle') || "";
      var modalText = target.attr('data-description') || "";
      var modalOK = target.attr('data-btnok') || "OK";
      var modalCancel = target.attr('data-btncancel') || "Annulla";
      var modalDownload_title = target.attr('data-download-title');
      var modalDownload_text = target.attr('data-download-text');
      var modalText2 = '';
      var textClass = '';

      setTimeout(function() {
          $('html, body').animate({
              scrollTop: $('body').offset().top
          }, 100);
      }, 100);

      var notePdf = '';
      if(target.attr('data-note')) {
          notePdf = '<small>' + target.attr('data-note') + '</small>';
      }

      var pdfEmbed = '<div class="shadow-modal"></div><div id="pdfIframe"></div>';

      if (download_pdf && application.pdf_type != '-article') application.generatePDF(download_pdf, application.pdf_type);

      if (application.pdf_type != '-article') {
          textClass = 'class="marginBottom15 pdf-text"';
          modalText2 = '<div class="input-wrapper">\
              <label class="hide"></label>\
              <div class="row">\
                  <div class="col-md-12">' + pdfEmbed + notePdf + '</div>\
              </div>\
          </div>';
      }else{
        textClass = 'class="marginBottom20 clausole-text"';
      }

      if (download_pdf && download_pdf.indexOf('data:application/pdf') == -1) {
          download_pdf = "data:application/pdf;base64,"+download_pdf;
      }

      var download_pdf_a = '';
      if(modalDownload_title.length > 0) {
          var download_pdf_a = '<a href="javascript:void(0)" data-download-pdf data-pdf="'+download_pdf+'">' + modalDownload_title + '</a> ';
      }

      application.confirm(

          '<div class="container-generic-structure setpin-modal-content no-padding">\
              <div class="textContainer padding15-30"> <div ' + textClass + '>' + modalSubTitle + '</div> <div>' + modalText + '</div>' + modalText2 + '</div>\
          </div>\
          <div class="container_btn_flex pdfStyle widthTotal">\
              <div class="content_flex_btn">\
                  <button class="cancel btn btn-link">' + modalCancel + '</button>\
                  <button class="ok btn btn-primary" disabled="">' + modalOK + '</button>\
              </div>\
              <div class="otp-other">' + download_pdf_a + modalDownload_text + ' </div>\
          </div>',
          modalTitle,
          function() {
            //inserisci otp
            application.showOtpManageModal(undefined, function() {
                if(application.pdf_type == '') {
                    application.objCardEnabled.firstOtp = true;
                    application.pdfRecapPayment('-article', download_pdf);
                } else {
                    if(application.objCardEnabled.firstOtp && application.pdf_type=='-article') {
                        application.objCardEnabled.secondOtp = true;

                        //conferma attivazione pagamento
                        application.typePaymentCard(confermaAttivazionePagamento, data_json, function(res) {
                            if(res) {
                                if(res.return_code && res.return_code.return_param == '00') {
                                    application.notify("<p class='h3'><strong>La tua richiesta è stata accettata</strong></p><p>La tua Carta socio è stata abilitata al pagamento della spesa e alle operazioni in cassa. Potrai <strong>visualizzare e scaricare il Contratto e la documentazione</strong> all'interno della sezione Documenti</p>", "success", "", 10000);
                                    setTimeout(function() {
                                        location.reload();
                                    }, 5000);
                                    return;
                                } else {
                                    application.notify("<strong>Errore</strong><br>Per motivi tecnici, abbiamo riscontrato un problema e non è stato possibile procedere con l’operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", "error", "", 10000);
                                }
                            } else {
                                application.notify("<strong>Errore</strong><br>Per motivi tecnici, abbiamo riscontrato un problema e non è stato possibile procedere con l’operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", "error", "", 10000);
                            }
                        });
                    }
                    if(application.pdf_type=='-disabled') {
                        //conferma disattivazione del pagamento
                        application.typePaymentCard(confermaDisattivazionePagamento, data_json, function(res) {
                            if(res) {
                                if(res.return_code && res.return_code.return_param == '00') {
                                    application.notify("<p class='h3'><strong>Operazione riuscita</strong></p><p>Da questo momento la tua Carta socio non è più attiva al pagamento della spesa e al prelievo. Potrai <strong>visualizzare e scaricare il Modulo di richiesta disattivazione</strong> all'interno della sezione Documenti</p>", "success", "", 10000);
                                    setTimeout(function() {
                                        location.reload();
                                    }, 5000);
                                    return;
                                }
                            }
                            application.notify("<strong>Errore</strong><br>Per motivi tecnici, abbiamo riscontrato un problema e non è stato possibile procedere con l’operazione. Ci scusiamo per il disagio e ti preghiamo di riprovare più tardi.", "error", "", 10000);
                        });
                    }
                }
            });
          },
          function() {
              application.confirmAnnul(function() {
                  application.pdfRecapPayment(pdf_type, download_pdf, data_json);
              });
          }

      );

      if (application.pdf_type != '-article') {
          //call the detect scroll function
          application.scrollDetect();
      }

      //add custom class to the modal
      $("body .dialog").addClass("managecards1015 modal-fullscreen");

      var thisModal = $(".setpin-modal-content").parents(".dialog");
      var thisOK = thisModal.find("button.ok");
      thisOK.prop("disabled", false);

      $(".managecards1015 .icon-close").off("click");
      
      $("[data-download-pdf]").off("click");
      $(document).on('click', '[data-download-pdf]', function(e) {
          application.downloadFile($(this).attr('data-pdf'), "documento.pdf", application.pdf_type == '-article');
          e.preventDefault();
      });

      $(document).on("click", ".managecards1015 .icon-close", function() {
          $("body .alertify").remove();
          application.confirmAnnul(function() {
              application.pdfRecapPayment(application.pdf_type, download_pdf, data_json);
          });
      });

    }

    this.confirmAnnul = function(callback) {
      var target = $('[data-annulconfirm]');

      var modalText = target.attr('data-text') || "";
      var modalTitle = target.attr('data-title') || "";
      var modalOK = target.attr('data-btnok') || "OK";
      var modalCancel = target.attr('data-btncancel') || "Annulla";

      application.confirm(
          "<div class='container-generic-structure setpin-modal-content'>\
              <div class='textContainer'><div>" + modalText + "</div></div>\
          </div>",
          modalTitle,
          function() {
              application.objCardEnabled = application.initEnabledObj();
          },
          function() {
              callback();
          },
          modalOK,
          modalCancel);

      //add custom class to the modal
      $("body .dialog").addClass("managecards800 nav modal-fullscreen hideClose");
    }

  };
}

/**
 * Get actual class instance
 *
*/
Prestito.Application.getInstance = function() {

  if (!window.prestito) {
    window.prestito = {};
  }

  if (!window.prestito.application) {
    window.prestito.application = new Prestito.Application();
  }

  return window.prestito.application;

};

/* --------------------- GLOBALS ------------------------------------------------------------------------------- */

/**
 * Watch object property
 *
*/
if (!Object.prototype.watch) {

  Object.defineProperty(Object.prototype, "watch", {
    enumerable: false,
    configurable: true,
    writable: false,
    value: function(prop, handler) {
      var oldval = this[prop],
        newval = oldval,
        getter = function() {
          return newval;
        },
        setter = function(val) {
          oldval = newval;
          newval = handler.call(this, prop, oldval, val);
          return newval;
        };

      if (delete this[prop]) { // can't watch constants
        Object.defineProperty(this, prop, {
          get: getter,
          set: setter,
          enumerable: true,
          configurable: true
        });
      }
    }

  });
}

/**
* Dom ready actions
* NB. ONLY THIS dom ready event in WHOLE application
* (unique entry-point, better to manage, debug etc...)
*
*/
$(document).ready(function() {

  // get application instance
  var application = Prestito.Application.getInstance();

  // application init
  application.boot();

  // NB: ONLY after the first run, set appStart as TRUE
  // If you don't want to re-apply events or execute code again when media query changes, use this snippet
  // if( application.appStart ) return;


});

 

/**
 * Get object length (properties number)
 *
*/
Object.size = function(obj) {

  var size = 0,
    key;
  for (key in obj)
    if (obj.hasOwnProperty(key)) size++;

  return size;

};

/**
 * Check presence of scrollbars
 *
*/
$.hasScrollBar = function() {
  return $(document).height() > $(window).height();
};

/**
 * Check vertical scrollbars width
 *
*/
$.getScrollbarWidth = function() {

  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;

  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;

};